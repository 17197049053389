import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import Loader from "../common/Loader/Loader";
import YetAnotherLightbox from "yet-another-react-lightbox";
import {
  Counter,
  Fullscreen,
  Zoom,
  Thumbnails,
} from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { useTranslation } from "react-i18next";

function RoomGallery({ postId, roomId }) {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [room, setRoom] = useState(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    const fetchPostAndRoom = async () => {
      const postRef = doc(db, "posts", postId);
      const postSnap = await getDoc(postRef);

      if (postSnap.exists()) {
        const fetchedPost = postSnap.data();
        setPost(fetchedPost);

        const roomData = fetchedPost.rooms.find(
          (room) => room.roomId === parseInt(roomId)
        );
        if (roomData) {
          setRoom(roomData);
        } else {
          console.error("Room not found!");
        }
      } else {
        console.error("Post not found!");
      }
      setLoading(false);
    };

    fetchPostAndRoom();
  }, [postId, roomId]);

  if (loading) {
    return <Loader />;
  }

  if (!post) {
    return (
      <div className="h-screen text-center text-xl font-bold py-20 flex justify-center items-center dark:text-gray-200 text-black">
        Post not found
      </div>
    );
  }

  if (!room) {
    return (
      <div className="h-screen text-center text-xl font-bold py-20 flex justify-center items-center dark:text-gray-200 text-black">
        Room not found
      </div>
    );
  }

  const allSlides = room.instances.flatMap((instance) =>
    instance.normalImages.map((img) => ({
      src: img,
    }))
  );
  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setLightboxOpen(true);
  };

  const getRoomType = () => {
    const RooomType = room.roomType.split("|");

    const languageMap = {
      en: RooomType[0],
      am: RooomType[1],
      ar: RooomType[2],
    };

    return languageMap[language] || RooomType[0];
  };

  return (
    <div className="max-w-7xl mx-auto px-4 pt-10 pb-20 lg:pb-5">
      <div className="flex justify-between mb-4">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100">
          {getRoomType()}
        </h1>
        <div className="flex justify-center items-center">
          <Link
            to={`/post/${postId}/${roomId}/3D-tour`}
            className="bg-mainColor text-white px-4 py-2 rounded-lg shadow-md hover:bg-hovermainColor transition duration-300"
          >
            3D tour
          </Link>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        {room.instances.flatMap((instance) =>
          instance.normalImages.map((image, index) => (
            <img
              key={`${instance.instanceId}-${index}`}
              src={image}
              alt={`${room.roomType} pic ${index + 1}`}
              className="w-full h-60 object-cover rounded-lg shadow-sm dark:shadow-md cursor-pointer"
              onClick={() => openLightbox(index)}
            />
          ))
        )}
      </div>

      {lightboxOpen && (
        <YetAnotherLightbox
          plugins={[Counter, Fullscreen, Zoom, Thumbnails]}
          open={lightboxOpen}
          close={() => setLightboxOpen(false)}
          slides={allSlides}
          index={currentImageIndex}
          styles={{ root: { "--yarl__color_backdrop": "rgba(0, 0, 0, .8)" } }}
        />
      )}
    </div>
  );
}

export default RoomGallery;

import React, { useState, useEffect, useContext } from "react";
import { Modal } from "antd";
import { auth, db, googleProvider } from "../../../firebase";
import { signInWithPopup, signInWithEmailAndPassword } from "firebase/auth";
import { FaGoogle, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

function LoginModal({ isLoginModalVisible, setIsLoginModalVisible }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });
  const { theme } = useContext(ThemeContext);

  const isDarkMode = theme === "dark";

  const handleGoogleSignIn = async () => {
    setGoogleLoading(true);
    setMessage({ type: "", text: "" });

    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        await updateDoc(userRef, {
          lastLogin: new Date().toISOString(),
        });
      } else {
        await setDoc(userRef, {
          email: user.email,
          firstName: user.displayName?.split(" ")[0] || "",
          lastName: user.displayName?.split(" ")[1] || "",
          role: "user",
          createdAt: new Date().toISOString(),
          lastLogin: new Date().toISOString(),
        });
      }

      setMessage({ type: "success", text: "Google Sign-in successful" });
      setIsLoginModalVisible(false);
    } catch (error) {
      setMessage({ type: "error", text: error.code });
    } finally {
      setGoogleLoading(false);
    }
  };

  const handleEmailPasswordSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ type: "", text: "" });

    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      const user = result.user;

      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, {
        lastLogin: new Date().toISOString(),
      });

      setMessage({
        type: "success",
        text: "Email/Password Sign-in successful",
      });
      setIsLoginModalVisible(false);
    } catch (error) {
      setMessage({ type: "error", text: error.code });
    } finally {
      setLoading(false);
    }
  };
  const handleLoginCancel = () => {
    setIsLoginModalVisible(false);
  };

  return (
    <div>
      <Modal
        open={isLoginModalVisible}
        onCancel={handleLoginCancel}
        closeIcon={
          <FaTimes className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100" />
        }
        centered
        footer={null}
        styles={{
          content: {
            backgroundColor: isDarkMode ? "#1f2937" : "#ffffff",
            borderRadius: "30px",
          },
        }}
      >
        <div className="font-Quicksand w-full bg-transparent max-w-lg xl:p-0">
          <div className="p-6 lg:m-5 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-gray-100 md:text-2xl">
              Log in to your account
            </h1>

            {message.text && (
              <div
                className={`mb-4 p-3 rounded ${
                  message.type === "success"
                    ? "bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-100"
                    : "bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-100"
                }`}
              >
                {message.text}
              </div>
            )}

            <form
              className="space-y-4 md:space-y-6"
              onSubmit={handleEmailPasswordSignIn}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="name@company.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  required
                />
              </div>

              <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                Forgot your password?{" "}
                <Link
                  to="/forgot-password"
                  className="font-medium text-mainColor hover:underline"
                >
                  Reset Password
                </Link>
              </p>

              <button
                type="submit"
                className="w-full text-gray-500 hover:text-gray-800 dark:hover:text-gray-300 font-medium bg-transparent border-2 border-gray-500 hover:border-gray-800 dark:border-gray-300 dark:hover:border-gray-400 focus:outline-none rounded-lg text-sm px-5 py-2.5 text-center"
                disabled={loading || googleLoading}
              >
                {loading ? "Logging in..." : "Log in"}
              </button>
            </form>

            <div className="relative mt-6 text-center">
              <div className="absolute inset-0 flex justify-between flex-row items-center">
                <div className="w-1/3 border-t border-gray-400 dark:border-gray-600"></div>
                <div className="w-1/3 border-t border-gray-400 dark:border-gray-600"></div>
              </div>
              <div className="relative px-4 text-gray-500 dark:text-gray-400">
                or
              </div>
            </div>

            <button
              onClick={handleGoogleSignIn}
              className="w-full py-2 rounded-lg bg-mainColor text-white font-medium hover:bg-hovermainColor dark:hover:bg-hovermainColor flex items-center justify-center focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {googleLoading ? (
                "Logging in with Google..."
              ) : (
                <>
                  <FaGoogle className="h-5 w-5 mr-2" />
                  Log in with Google
                </>
              )}
            </button>

            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
              Don't have an account?{" "}
              <Link
                to="/register"
                className="font-medium text-mainColor hover:underline"
              >
                Register
              </Link>
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default LoginModal;

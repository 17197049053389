import { motion } from "framer-motion";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { useEffect, useState } from "react"; // Import useEffect and useState
import { collection, getDocs } from "firebase/firestore"; // Import Firebase functions
import { db } from "../../../firebase"; // Import your Firebase configuration

const COLORS = [
  "#6366F1",
  "#8B5CF6",
  "#EC4899",
  "#10B981",
  "#F59E0B",
  "#FBBF24",
  "#34D399",
  "#60A5FA",
  "#F472B6",
];

const CategoryDistributionChart = () => {
  const [categoryData, setCategoryData] = useState([]); // State for category data

  useEffect(() => {
    const fetchCategoryData = async () => {
      const postsCollection = collection(db, "posts"); // Adjust to your actual posts collection name
      const postsSnapshot = await getDocs(postsCollection);
      const posts = postsSnapshot.docs.map((doc) => doc.data());

      // Create a category count object
      const categoryCount = {};

      posts.forEach((post) => {
        const category = post.category; // Adjust based on your data structure
        if (categoryCount[category]) {
          categoryCount[category]++;
        } else {
          categoryCount[category] = 1;
        }
      });

      // Convert the category count object to an array for the pie chart
      const data = Object.entries(categoryCount).map(([name, value]) => ({
        name,
        value,
      }));

      setCategoryData(data);
    };

    fetchCategoryData();
  }, []);

  return (
    <motion.div
      className="bg-gray-800 bg-opacity-50 h-fit backdrop-blur-md shadow-lg rounded-xl p-6 border border-gray-700"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.3 }}
    >
      <h2 className="text-lg font-medium mb-4 text-gray-100">
        Category Distribution
      </h2>
      <div className="h-80 flex items-center justify-center">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={categoryData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              label={({ name, percent }) =>
                `${name} ${(percent * 100).toFixed(0)}%`
              }
            >
              {categoryData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip
              contentStyle={{
                backgroundColor: "rgba(31, 41, 55, 0.8)",
                borderColor: "#4B5563",
              }}
              itemStyle={{ color: "#E5E7EB" }}
            />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </motion.div>
  );
};

export default CategoryDistributionChart;

import React, { useState, useEffect, useRef } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  orderBy,
  onSnapshot,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import MessageInput from "./MessageInput";
import Message from "./Message";
import { TailSpin } from "react-loader-spinner";
import { FaAngleLeft } from "react-icons/fa";
import { Link } from "react-router-dom";

const ChatArea = ({ chatId, setSelectedChatId }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [conversationId, setConversationId] = useState(null);
  const [conversation, setConversation] = useState(null);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const fetchConversationId = async () => {
      try {
        const conversationsRef = collection(db, "conversations");
        const q = query(conversationsRef, where("chatId", "==", chatId));

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          setConversationId(doc.id);
          setConversation(doc.data());
        } else {
          console.error("No conversation found for this chatId.");
        }
      } catch (error) {
        console.error("Error fetching conversation:", error);
      }
    };

    fetchConversationId();
  }, [chatId]);

  const markConversationAsRead = async () => {
    if (conversationId) {
      try {
        const conversationRef = doc(db, "conversations", conversationId);
        await updateDoc(conversationRef, {
          adminLastMessageStatus: "read",
        });
      } catch (error) {
        console.error("Error updating conversation status:", error);
      }
    }
  };

  useEffect(() => {
    if (conversationId) {
      const messagesRef = collection(
        db,
        "conversations",
        conversationId,
        "messages"
      );
      const q = query(messagesRef, orderBy("createdAt", "asc"));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const messagesData = snapshot.docs.map((doc) => {
          const data = doc.data();

          const createdAt =
            data.createdAt instanceof Timestamp
              ? data.createdAt.toDate().toISOString()
              : data.createdAt;

          return {
            id: doc.id,
            ...data,
            createdAt,
          };
        });

        setMessages(messagesData);
        setLoading(false);

        if (messagesData.length > 0) {
          markConversationAsRead();
          scrollToBottom();
        }
      });

      return () => unsubscribe();
    }
  }, [conversationId]);

  useEffect(() => {
    if (!loading) {
      const timer = setTimeout(scrollToBottom, 100);
      return () => clearTimeout(timer);
    }
  }, [messages, loading]);

  if (loading) {
    return (
      <div className="lg:w-2/4 w-full text-center text-gray-500 flex items-center justify-center space-x-4 text-lg font-medium">
        <TailSpin color="#00a3cc" height={20} width={20} strokeWidth={4} />
        <span>Loading...</span>
      </div>
    );
  }

  const handleBackToChats = () => {
    setSelectedChatId(null);
  };

  return (
    <div className="lg:w-2/4 w-full relative flex flex-col justify-between h-full">
      <div className="absolute top-0 h-14 w-full dark:bg-backgroundDark bg-backgroundColor border-b border-gray-300 dark:border-gray-600 text-black dark:text-white flex items-center justify-center font-bold text-lg shadow z-10">
        <button
          onClick={handleBackToChats}
          className="absolute lg:hidden block top-4 left-4 text-black dark:text-white px-4 py-2 rounded-full"
        >
          <FaAngleLeft />
        </button>
        <Link to={`/post/${conversation.postId}`}>
          {conversation ? conversation.postName : "Chat"}
        </Link>
      </div>

      <div className="p-5 mt-12 mb-10 overflow-y-auto no-scrollbar">
        {messages.length === 0 ? (
          <p className="text-gray-600 flex justify-center items-center text-center">
            No messages yet.
          </p>
        ) : (
          messages.map((msg) => <Message key={msg.id} message={msg} />)
        )}
        <div ref={messagesEndRef} />
      </div>

      <div className="absolute bottom-0 right-0 left-0">
        <MessageInput
          chatId={chatId}
          conversationId={conversationId}
          scrollToBottom={scrollToBottom}
        />
      </div>
    </div>
  );
};

export default ChatArea;

import React, { useEffect, useRef, useState } from "react";
import {
  Circle,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import {
  CircleDollarSign,
  Coffee,
  GraduationCap,
  Hospital,
  Utensils,
} from "lucide-react";

const SchoolIcon = L.icon({
  iconUrl: require("../../assets/pins/school.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const BankIcon = L.icon({
  iconUrl: require("../../assets/pins/bank.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const RestaurantIcon = L.icon({
  iconUrl: require("../../assets/pins/restaurant.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const CafeIcon = L.icon({
  iconUrl: require("../../assets/pins/cafe.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const ClinicIcon = L.icon({
  iconUrl: require("../../assets/pins/hospital.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const DefaultIcon = L.icon({
  iconUrl: require("../../assets/pins/main.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

function LocationDetail({ post }) {
  const [pois, setPois] = useState([]);
  const [loadingType, setLoadingType] = useState("");
  const [selectedType, setSelectedType] = useState("");

  const mapRef = useRef();

  const ScrollControl = () => {
    const map = useMap();

    useEffect(() => {
      const handleWheel = (e) => {
        if (e.ctrlKey) {
          map.scrollWheelZoom.enable();
        } else {
          map.scrollWheelZoom.disable();
        }
      };

      map.scrollWheelZoom.disable();
      map.getContainer().addEventListener("wheel", handleWheel);
      return () => {
        map.getContainer().removeEventListener("wheel", handleWheel);
      };
    }, [map]);

    return null;
  };

  const fetchPOIs = (type) => {
    setSelectedType(type);
    setLoadingType(type);
    const lat = post.location.coordinates.lat;
    const long = post.location.coordinates.long;

    if (type === "clinic_hospital") {
      const clinicUrl = `https://overpass-api.de/api/interpreter?data=[out:json];node(around:1000,${lat},${long})[amenity=clinic];out;`;
      const hospitalUrl = `https://overpass-api.de/api/interpreter?data=[out:json];node(around:1000,${lat},${long})[amenity=hospital];out;`;

      Promise.all([fetch(clinicUrl), fetch(hospitalUrl)])
        .then((responses) => Promise.all(responses.map((res) => res.json())))
        .then(([clinicData, hospitalData]) => {
          const combinedData = [
            ...clinicData.elements,
            ...hospitalData.elements,
          ];
          const points = combinedData.map((element) => ({
            lat: element.lat,
            lon: element.lon,
            name: element.tags.name || "Clinic/Hospital",
          }));
          setPois(points);
          setLoadingType("");
        })
        .catch((error) => {
          console.error("Error fetching POIs:", error);
          setLoadingType("");
        });
    } else {
      const overpassUrl = `https://overpass-api.de/api/interpreter?data=[out:json];node(around:1000,${lat},${long})[amenity=${type}];out;`;

      fetch(overpassUrl)
        .then((response) => response.json())
        .then((data) => {
          const points = data.elements.map((element) => ({
            lat: element.lat,
            lon: element.lon,
            name:
              element.tags.name || type.charAt(0).toUpperCase() + type.slice(1),
          }));
          setPois(points);
          setLoadingType("");
        })
        .catch((error) => {
          console.error("Error fetching POIs:", error);
          setLoadingType("");
        });
    }
  };

  const getIcon = (type) => {
    if (type === "school") return SchoolIcon;
    if (type === "bank") return BankIcon;
    if (type === "restaurant") return RestaurantIcon;
    if (type === "cafe") return CafeIcon;
    if (type === "clinic_hospital") return ClinicIcon;
    return DefaultIcon;
  };

  return (
    <div className="mt-6">
      <h2 className="text-xl text-black dark:text-gray-300 font-medium mb-4">
        Location
      </h2>

      <MapContainer
        center={[post.location.coordinates.lat, post.location.coordinates.long]}
        zoom={14}
        className="h-72 lg:h-80 -z-0 border-x border-t border-gray-400 dark:border-gray-500"
        ref={mapRef}
      >
        <ScrollControl />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        <Marker
          position={[
            post.location.coordinates.lat,
            post.location.coordinates.long,
          ]}
          icon={DefaultIcon}
        >
          <Popup>
            <p className="font-Quicksand font-bold flex justify-center text-sm">
              {post.location.name}
            </p>
            <p className="text-xs">
              Exact location will be provided after purchase.
            </p>
          </Popup>
        </Marker>

        <Circle
          center={[
            post.location.coordinates.lat,
            post.location.coordinates.long,
          ]}
          radius={1000}
          pathOptions={{
            color: "#00a3cc",
            opacity: 0.5,
            fillColor: "#00a3cc",
            fillOpacity: 0.3,
          }}
        >
          <Popup>
            <p className="font-Quicksand font-bold flex justify-center text-sm">
              {post.location.name}
            </p>
            <p className="text-xs">
              Exact location will be provided after purchase.
            </p>
          </Popup>
        </Circle>

        {pois.map((poi, index) => (
          <Marker
            key={index}
            position={[poi.lat, poi.lon]}
            icon={getIcon(selectedType)}
          >
            <Popup>
              <span className="font-Quicksand font-semibold">{poi.name}</span>
            </Popup>
          </Marker>
        ))}
      </MapContainer>

      <div className="grid grid-cols-2 lg:flex lg:flex-row justify-between  ">
        <button
          onClick={() => fetchPOIs("school")}
          className="px-4 py-2 lg:w-full border-x border-b text-black dark:text-white border-gray-400 hover:bg-gray-300 dark:hover:bg-gray-700"
        >
          {loadingType === "school" ? (
            "Loading..."
          ) : (
            <div className="flex space-x-2">
              <GraduationCap className="text-sm text-sky-400" />{" "}
              <span>Schools</span>
            </div>
          )}
        </button>
        <button
          onClick={() => fetchPOIs("bank")}
          className="px-4 py-2 lg:w-full border-x border-b text-black dark:text-white border-gray-400 hover:bg-gray-300 dark:hover:bg-gray-700"
        >
          {loadingType === "bank" ? (
            "Loading..."
          ) : (
            <div className="flex space-x-2">
              <CircleDollarSign className="text-sm text-green-400" />{" "}
              <span>Banks</span>
            </div>
          )}
        </button>
        <button
          onClick={() => fetchPOIs("restaurant")}
          className="px-4 py-2 lg:w-full border-x border-b text-black dark:text-white border-gray-400 hover:bg-gray-300 dark:hover:bg-gray-700"
        >
          {loadingType === "restaurant" ? (
            "Loading..."
          ) : (
            <div className="flex space-x-2">
              <Utensils className="text-sm text-pink-300" />{" "}
              <span>Restaurants</span>
            </div>
          )}
        </button>
        <button
          onClick={() => fetchPOIs("cafe")}
          className="px-4 py-2 lg:w-full border-x border-b text-black dark:text-white border-gray-400 hover:bg-gray-300 dark:hover:bg-gray-700"
        >
          {loadingType === "cafe" ? (
            "Loading..."
          ) : (
            <div className="flex space-x-2">
              <Coffee className="text-sm text-slate-600" /> <span>Cafes</span>
            </div>
          )}
        </button>
        <button
          onClick={() => fetchPOIs("clinic_hospital")}
          className="px-4 py-2 lg:w-full border-x border-b text-black dark:text-white border-gray-400 hover:bg-gray-300 dark:hover:bg-gray-700"
        >
          {loadingType === "clinic_hospital" ? (
            "Loading..."
          ) : (
            <div className="flex space-x-2">
              <Hospital className="text-sm text-rose-400" />{" "}
              <span>Health Centers</span>
            </div>
          )}
        </button>
      </div>
    </div>
  );
}

export default LocationDetail;

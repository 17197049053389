import React from "react";
import { FaAngleLeft } from "react-icons/fa";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

function ChatSidebar({
  conversations,
  selectedChatId,
  setSelectedChatId,
  loading,
}) {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const sortedConversations = [...conversations].sort((a, b) => {
    return new Date(b.updatedAt) - new Date(a.updatedAt);
  });

  return (
    <div className="lg:w-1/4 w-full h-screen bg-gray-100 dark:bg-gray-700 py-6 border-r border-gray-300 dark:border-gray-600 overflow-y-auto">
      <div className="flex items-center space-x-4 mb-4 px-6 border-b border-gray-300 dark:border-gray-600">
        <button
          onClick={goBack}
          className="text-xl mb-4 p-2 text-gray-800 dark:text-gray-200 hover:bg-gray-500 rounded-full"
        >
          <FaAngleLeft />
        </button>
        <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-gray-200 text-center">
          Chats
        </h2>
      </div>

      {loading ? (
        <div className="flex justify-center items-center text-center h-3/4">
          <TailSpin
            visible={loading}
            height="50"
            width="50"
            color="#00a3cc"
            ariaLabel="loading"
          />
        </div>
      ) : (
        <>
          {conversations.length > 0 ? (
            <ul className="space-y-2 pl-2">
              {sortedConversations.map((chat) => (
                <li key={chat.id}>
                  <button
                    onClick={() => setSelectedChatId(chat.chatId)}
                    className={`relative flex items-center justify-between p-5 w-full  transition-transform transform ${
                      selectedChatId === chat.chatId
                        ? "border-l-4 border-mainColor"
                        : ""
                    }`}
                    style={{
                      backgroundImage: `url(${chat.postImg})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div
                      className={`absolute inset-0 bg-black transition-opacity ${
                        selectedChatId === chat.chatId
                          ? "opacity-30 dark:opacity-20"
                          : "opacity-50 dark:opacity-30 hover:opacity-60 dark:hover:opacity-40"
                      }`}
                    ></div>
                    <div className="relative z-10">
                      <p
                        className={`font-bold text-white transition-all duration-200 ${
                          selectedChatId === chat.chatId
                            ? "text-yellow-300"
                            : "text-white"
                        }`}
                      >
                        {chat.postName}
                      </p>
                    </div>
                    {chat.adminLastMessageStatus === "unread" && (
                      <div className="h-3 w-3 bg-red-500 rounded-full relative z-10" />
                    )}
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <div className="flex justify-center items-center text-center h-3/4 text-gray-800 dark:text-gray-400 ">
              No conversations have been started yet.
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ChatSidebar;

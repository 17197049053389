import React, { useState, useContext, useEffect } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { HiOutlineBookmark, HiMiniBookmark } from "react-icons/hi2";
import { GoHome, GoHomeFill } from "react-icons/go";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import LoginModal from "../login-modal/LoginModal";
import { IoMoon, IoSunny } from "react-icons/io5";
import { ThemeContext } from "../../../context/ThemeContext";
import { MessageCircleMore } from "lucide-react";
import { FaPlus } from "react-icons/fa";

const FooterNav = ({ unreadCount }) => {
  const { currentUser, firstName } = useAuth();
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const toggleMenu = () => {
    if (currentUser) {
      navigate("/request-form");
    } else {
      setIsLoginModalVisible(true);
    }
  };

  const isActive = (path) => location.pathname === path;

  return (
    <div className="lg:hidden bg-gray-100 dark:bg-gray-800 fixed -bottom-[2px] w-full max-h-16 px-10 py-4 rounded-lt-lg shadow-lg border-t-[1px] border-gray-300 dark:border-gray-600 z-40 transition-colors duration-300">
      <div
        className={`flex items-center ${
          currentUser ? "justify-between" : "justify-between mx-10"
        }`}
      >
        <div className="absolute bottom-[60px] bg-gray-100 dark:bg-gray-800 border-t-[1px] border-l-[1px] border-gray-300 dark:border-gray-600 rounded-tl-2xl p-2 right-0 flex items-center space-x-4 z-20">
          <button
            onClick={toggleTheme}
            className="text-2xl focus:outline-none transition-transform transform duration-500 dark:rotate-0 rotate-180"
          >
            {theme === "dark" ? (
              <IoSunny className="text-yellow-300" />
            ) : (
              <IoMoon className="rotate-180" />
            )}
          </button>
        </div>
        <Link to="/" className="w-7 flex flex-col items-center">
          <button>
            {isActive("/") ? (
              <GoHomeFill className="text-2xl cursor-pointer text-black dark:text-white" />
            ) : (
              <GoHome className="text-2xl cursor-pointer text-gray-600 dark:text-gray-300" />
            )}
          </button>
        </Link>
        {currentUser ? (
          <>
            <Link
              to="/messages"
              className="relative w-7 flex flex-col items-center"
            >
              {unreadCount > 0 && (
                <div className="absolute h-4 w-4 rounded-full bg-red-500 -top-1 -right-1 flex justify-center items-center">
                  <span className="text-xs text-center font-semibold text-white">
                    {unreadCount}
                  </span>
                </div>
              )}
              <button>
                <MessageCircleMore
                  className={`text-2xl cursor-pointer ${
                    isActive("/messages")
                      ? "text-black dark:text-white"
                      : "text-gray-600 dark:text-gray-300"
                  }`}
                />
              </button>
            </Link>

            <div className="w-7 flex flex-col items-center">
              <Link to="/request-form">
                <FaPlus
                  className={`text-2xl cursor-pointer ${
                    isActive("/request-form")
                      ? "text-black dark:text-white"
                      : "text-gray-600 dark:text-gray-300"
                  }`}
                />
              </Link>
            </div>
          </>
        ) : (
          <div className="w-7 flex flex-col items-center">
            <button onClick={toggleMenu}>
              <FaPlus
                className={`text-2xl cursor-pointer ${
                  isActive("/request-form")
                    ? "text-black dark:text-white"
                    : "text-gray-600 dark:text-gray-300"
                }`}
              />
            </button>
          </div>
        )}

        {currentUser && (
          <Link to="/saved" className="w-7 flex flex-col items-center">
            <button>
              {isActive("/saved") ? (
                <HiMiniBookmark className="text-2xl cursor-pointer text-black dark:text-white" />
              ) : (
                <HiOutlineBookmark className="text-2xl cursor-pointer text-gray-600 dark:text-gray-300" />
              )}
            </button>
          </Link>
        )}
        {currentUser ? (
          <Link to="/profile" className="w-7 flex items-center justify-end">
            <button>
              <div
                className={`h-7 w-7 border-2 rounded-full bg-gradient-to-r from-purple-400 to-blue-500 flex items-center justify-center text-white font-semibold ${
                  isActive("/profile")
                    ? "border-black dark:border-white dark:ring-white  ring-black ring-1"
                    : "border-gray-600 dark:border-gray-400 ring-gray-600 dark:ring-gray-400"
                }`}
              >
                {firstName ? firstName.charAt(0) : "?"}
              </div>
            </button>
          </Link>
        ) : (
          <div className="w-7 flex flex-col items-center">
            <button onClick={toggleMenu}>
              <AiOutlineUser className="text-3xl cursor-pointer text-gray-600 dark:text-gray-300" />
            </button>
          </div>
        )}
      </div>
      <LoginModal
        isLoginModalVisible={isLoginModalVisible}
        setIsLoginModalVisible={setIsLoginModalVisible}
      />
    </div>
  );
};

export default FooterNav;

import React, { useState, useEffect, useContext } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { FaFacebookSquare, FaInstagram, FaTimes } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { BsWhatsapp } from "react-icons/bs";
import { PiShareFatFill } from "react-icons/pi";
import { HiOutlineBookmark, HiMiniBookmark } from "react-icons/hi2";
import { Button, Modal } from "antd";
import { Clipboard, Mail, Send, SendHorizontal, X } from "lucide-react";
import { ThemeContext } from "../../context/ThemeContext";
import toast from "react-hot-toast";
import { useAuth } from "../../context/AuthContext";
import PostCard from "./PostCard";
import LocationDetail from "./LocationDetail";
import Loader from "../common/Loader/Loader";
import LoginModal from "../common/login-modal/LoginModal";
import YetAnotherLightbox from "yet-another-react-lightbox";
import {
  Counter,
  Fullscreen,
  Zoom,
  Thumbnails,
} from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import RoomGallery from "./RoomGallary";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import DetailSection from "./DetailSection";
import DetailsImages from "./DetailsImages";

function PostDetail() {
  const { id } = useParams();
  const { currentUser, firstName, lastName } = useAuth();
  const { theme } = useContext(ThemeContext);
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saved, setSaved] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [isContactModalVisible, setIsContactModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isRoomGalleryOpen, setIsRoomGalleryOpen] = useState(false);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const isDarkMode = theme === "dark";

  useEffect(() => {
    const checkIfSaved = async () => {
      const uid = currentUser?.uid;
      const savedDocRef = doc(db, "saved", id);
      try {
        const docSnapshot = await getDoc(savedDocRef);
        if (docSnapshot.exists() && docSnapshot.data().uid === uid) {
          setSaved(true);
        } else {
          setSaved(false);
        }
      } catch (error) {
        console.error("Error checking if post is saved:", error);
      }
    };

    checkIfSaved();
  }, [id]);

  const handleSaveClick = async () => {
    if (currentUser) {
      const postId = id;
      const uid = currentUser?.uid;
      const savedDocRef = doc(db, "saved", postId);

      try {
        if (!saved) {
          await setDoc(savedDocRef, {
            uid: uid,
            createdAt: new Date().toISOString(),
          });
        } else {
          await deleteDoc(savedDocRef);
        }
        setSaved(!saved);
      } catch (error) {
        console.error("Error saving post:", error);
      }
    } else {
      setIsLoginModalVisible(true);
    }
  };

  const handleShareClick = () => {
    setIsShareModalVisible(true);
  };

  const handleShareModalClose = () => {
    setIsShareModalVisible(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.success("Link copied to clipboard!");
    setIsShareModalVisible(false);
  };

  const handleContactClick = () => {
    if (currentUser) {
      setIsContactModalVisible(true);
    } else {
      setIsLoginModalVisible(true);
    }
  };

  const handleContactModalClose = () => {
    setIsContactModalVisible(false);
  };

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  useEffect(() => {
    const fetchPost = async () => {
      const docRef = doc(db, "posts", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setPost(docSnap.data());
      } else {
        console.error("No such document!");
        navigate("/error");
      }
      setLoading(false);
    };

    fetchPost();
  }, [id]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const chatId = id + currentUser.uid;
    const customerName = firstName + " " + lastName;

    try {
      const conversationsRef = collection(db, "conversations");
      const q = query(conversationsRef, where("chatId", "==", chatId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const toastId = toast.loading(
          "Chat already exists, Forwarding you to messages page"
        );
        setTimeout(() => {
          navigate(`/messages`);
          toast.dismiss(toastId);
        }, 2000);
      } else {
        const conversationDocRef = await addDoc(
          collection(db, "conversations"),
          {
            chatId: chatId,
            customerId: currentUser.uid,
            customerName: customerName,
            postId: id,
            postName: post.name,
            postImg: post.outside.normalImages[0],
            status: "pending",
            customerLastMessageStatus: "unread",
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          }
        );

        const messageDocRef = await addDoc(
          collection(db, "conversations", conversationDocRef.id, "messages"),
          {
            senderId: currentUser.uid,
            message: message,
            createdAt: serverTimestamp(),
          }
        );
        setMessage("");
        handleContactModalClose();
        navigate(`/messages`);
      }
    } catch (error) {
      console.error("Error submitting: ", error);
      toast.error("Error submitting, please try again.");
    }
  };

  const toggleRoomGallery = (roomId) => {
    setIsRoomGalleryOpen((prev) => !prev);
    setSelectedRoomId(roomId);
  };

  if (loading) {
    return <Loader />;
  }

  if (!post) {
    return (
      <div className="h-screen text-center text-xl font-bold py-20 flex justify-center items-center dark:text-gray-200 text-black">
        Post not found
      </div>
    );
  }

  const allImages = [
    ...post.outside.normalImages,
    ...post.rooms.flatMap((room) =>
      room.instances.flatMap((instance) => instance.normalImages)
    ),
    ...post.surrounding.normalImages,
  ];

  const allSlides = allImages.map((img) => ({
    src: img,
  }));

  return (
    <div className="mt-[4.5rem] mx-auto px-4 pb-8 max-w-7xl">
      <div className="justify-end mr-4 flex space-x-4 mb-5">
        <button onClick={handleShareClick}>
          <PiShareFatFill className="text-gray-500 text-xl hover:text-gray-700" />
        </button>
        <button onClick={handleSaveClick}>
          {saved ? (
            <HiMiniBookmark className="text-mainColor text-xl" />
          ) : (
            <HiOutlineBookmark className="text-gray-500 text-xl hover:text-gray-700" />
          )}
        </button>
      </div>

      <DetailsImages
        allImages={allImages}
        setCurrentImageIndex={setCurrentImageIndex}
        setLightboxOpen={setLightboxOpen}
      />

      <div className="flex flex-col md:flex-row gap-8 border-t mt-12 pt-12 dark:border-gray-700">
        <DetailSection
          post={post}
          toggleRoomGallery={toggleRoomGallery}
          id={id}
        />

        <div className="relative md:sticky md:top-24 w-full h-fit md:w-[35%] shadow-md p-4 rounded-lg flex flex-col justify-center items-center border dark:border-gray-700">
          <h3 className="text-lg mb-5 font-medium text-black dark:text-gray-300">
            Interested in this property?
          </h3>
          <button
            onClick={handleContactClick}
            className="w-full bg-mainColor text-white p-2 rounded hover:bg-hovermainColor"
          >
            Contact Us
          </button>
        </div>
      </div>

      <LoginModal
        isLoginModalVisible={isLoginModalVisible}
        setIsLoginModalVisible={setIsLoginModalVisible}
      />

      <LocationDetail post={post} />

      {/* ///Sharing modal//// */}

      <Modal
        centered
        open={isShareModalVisible}
        onCancel={handleShareModalClose}
        closeIcon={
          <FaTimes className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100" />
        }
        footer={null}
        styles={{
          content: {
            backgroundColor: isDarkMode ? "#1f2937" : "#ffffff",
            borderRadius: "25px",
          },
        }}
      >
        <h1 className="text-xl py-3 font-bold leading-tight tracking-tight text-gray-900 dark:text-gray-100 md:text-2xl">
          Share this post
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-5">
          <Button
            className="flex justify-center space-x-2 px-3 py-6 border dark:bg-backgroundDark border-gray-400 dark:border-gray-600 hover:dark:bg-gray-700 rounded-xl font-semibold dar dark:text-white"
            onClick={copyToClipboard}
          >
            <Clipboard className="text-mainColor text-xl" />
            <span>Copy Link</span>
          </Button>
          <Button
            className="flex justify-center space-x-2 px-3 py-6 border dark:bg-backgroundDark border-gray-400 dark:border-gray-600 hover:dark:bg-gray-700 rounded-xl font-semibold dark:text-white"
            href={`mailto:?body=${window.location.href}`}
            target="_blank"
          >
            <Mail className="text-mainColor text-xl" />
            <span>Share via Email</span>
          </Button>
          <Button
            className="flex justify-center space-x-2 px-3 py-6 border dark:bg-backgroundDark border-gray-400 dark:border-gray-600 hover:dark:bg-gray-700 rounded-xl font-semibold dark:text-white"
            href={`https://t.me/share/url?url=${window.location.href}`}
            target="_blank"
          >
            <Send className="text-mainColor text-xl" />
            <span>Share on Telegram</span>
          </Button>
          <Button
            className="flex justify-center space-x-2 px-3 py-6 border dark:bg-backgroundDark border-gray-400 dark:border-gray-600 hover:dark:bg-gray-700 rounded-xl font-semibold dark:text-white"
            href={`https://twitter.com/intent/tweet?url=${window.location.href}`}
            target="_blank"
          >
            <FaXTwitter className="text-mainColor text-xl" />
            <span>Share on X (Twitter)</span>
          </Button>
          <Button
            className="flex justify-center space-x-2 px-3 py-6 border dark:bg-backgroundDark border-gray-400 dark:border-gray-600 hover:dark:bg-gray-700 rounded-xl font-semibold dark:text-white"
            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
            target="_blank"
          >
            <FaFacebookSquare className="text-mainColor text-xl" />
            <span>Share on Facebook</span>
          </Button>
          <Button
            className="flex justify-center space-x-2 px-3 py-6 border dark:bg-backgroundDark border-gray-400 dark:border-gray-600 hover:dark:bg-gray-700 rounded-xl font-semibold dark:text-white"
            href={`https://www.instagram.com/?url=${window.location.href}`}
            target="_blank"
          >
            <FaInstagram className="text-mainColor text-xl" />
            <span>Share on Instagram</span>
          </Button>
          <Button
            className="flex justify-center space-x-2 px-3 py-6 border dark:bg-backgroundDark border-gray-400 dark:border-gray-600 hover:dark:bg-gray-700 rounded-xl font-semibold dark:text-white"
            href={`https://wa.me/?text=${window.location.href}`}
            target="_blank"
          >
            <BsWhatsapp className="text-mainColor text-xl" />
            <span>Share on WhatsApp</span>
          </Button>
        </div>
      </Modal>

      {/* ///Messaging modal//// */}
      <Modal
        centered
        open={isContactModalVisible}
        onCancel={handleContactModalClose}
        footer={null}
        closeIcon={
          <FaTimes className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100" />
        }
        styles={{
          content: {
            backgroundColor: isDarkMode ? "#1f2937" : "#ffffff",
            borderRadius: "25px",
          },
        }}
      >
        <h1 className="text-xl py-3 font-bold leading-tight tracking-tight text-gray-900 dark:text-gray-100 md:text-2xl">
          Contact us
        </h1>
        <form onSubmit={handleFormSubmit} className="">
          <div className="flex flex-col">
            <div onClick={handleContactModalClose} className="z-30">
              <PostCard post={post} id={id} />
            </div>

            <p className="flex mt-10 mb-4 p-2 bg-gray-400 dark:bg-gray-700 border-l-4 border-black rounded-lg lg:text-sm text-base justify-center text-center text-gray-700 dark:text-gray-300">
              Start a conversation about this post. Our team will respond to you
              in 3 min.
            </p>

            <div className=" flex flex-col space-y-4 p-4">
              <div className="relative flex justify-start">
                <div className="absolute w-5 h-5 -left-[0.4rem] bottom-0 bg-gray-500 dark:bg-gray-600 rounded-br-2xl"></div>
                <div className="absolute w-5 h-5 -left-[1.25rem] bottom-0 bg-white dark:bg-gray-800 rounded-br-xl"></div>
                <div className="max-w-xs py-2 px-4 bg-gray-500 dark:bg-gray-600 text-white rounded-2xl">
                  <span className="block">Our team is waiting for you.</span>
                </div>
              </div>

              <div className="relative flex justify-start">
                <div className="absolute w-5 h-5 -left-[0.4rem] bottom-0 bg-gray-500 dark:bg-gray-600 rounded-br-2xl"></div>
                <div className="absolute w-5 h-5 -left-[1.25rem] bottom-0 bg-white dark:bg-gray-800 rounded-br-xl"></div>
                <div className="max-w-xs py-3 px-4 bg-gray-500 dark:bg-gray-600 text-white rounded-2xl flex items-center space-x-1">
                  <div className="w-2 h-2 rounded-full bg-white animate-flash"></div>
                  <div
                    className="w-2 h-2 rounded-full bg-white animate-flash "
                    style={{ animationDelay: "0.2s" }}
                  ></div>
                  <div
                    className="w-2 h-2 rounded-full bg-white animate-flash"
                    style={{ animationDelay: "0.4s" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-4">
            <div className="flex w-full space-x-4">
              <input
                id="message"
                name="message"
                type="text"
                placeholder="Enter message ..."
                className="w-full p-2 h-10 rounded-lg resize-none bg-white border border-gray-500 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600"
                value={message}
                onChange={handleInputChange}
              />
              <button
                type="submit"
                name="submit"
                disabled={!message}
                className="w-fit text-sm bg-mainColor text-white p-2 rounded-lg hover:bg-hovermainColor disabled:bg-gray-600"
              >
                <SendHorizontal />
              </button>
            </div>
          </div>
        </form>
      </Modal>

      {/* //lightbox */}

      {lightboxOpen && (
        <YetAnotherLightbox
          plugins={[Counter, Fullscreen, Zoom, Thumbnails]}
          open={lightboxOpen}
          close={() => setLightboxOpen(false)}
          slides={allSlides}
          index={currentImageIndex}
          styles={{ root: { "--yarl__color_backdrop": "rgba(0, 0, 0, .8)" } }}
        />
      )}

      {isRoomGalleryOpen && (
        <>
          <div
            className="fixed hidden lg:block h-full w-full bg-black bg-opacity-50 top-0 left-0"
            onClick={toggleRoomGallery}
          />

          {/* Large Screen Animation */}
          <motion.div
            className="fixed hidden lg:block bottom-0 w-[90%] right-0 h-full bg-backgroundColor dark:bg-backgroundDark border border-gray-300 dark:border-gray-700 z-30 overflow-y-auto no-scrollbar shadow-2xl"
            initial={{ x: "100%", y: "0" }}
            animate={{ x: 0, y: 0 }}
            exit={{ x: "100%", y: "0" }}
            transition={{ duration: 0.5, ease: "linear" }}
          >
            <div className="fixed w-[90%] top-0 right-0 p-4 bg-backgroundColor dark:bg-backgroundDark border border-gray-300 dark:border-gray-700">
              <div className="flex justify-end">
                <button
                  onClick={toggleRoomGallery}
                  className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200 focus:outline-none"
                  aria-label="Close gallery"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
            </div>

            <div className="pt-12 p-4">
              <RoomGallery postId={id} roomId={selectedRoomId} />
            </div>
          </motion.div>

          {/* Small Screen Animation */}
          <motion.div
            className="fixed block lg:hidden bottom-0 w-full lg:w-[90%] right-0 h-full bg-backgroundColor dark:bg-backgroundDark border border-gray-300 dark:border-gray-700 z-30 overflow-y-auto no-scrollbar shadow-2xl"
            initial={{ y: "100%", x: "0" }}
            animate={{ y: 0, x: 0 }}
            exit={{ y: "100%", x: "0" }}
            transition={{ duration: 0.5, ease: "linear" }}
          >
            <div className="fixed w-full lg:w-[90%] top-0 right-0 p-4 bg-backgroundColor dark:bg-backgroundDark border border-gray-300 dark:border-gray-700">
              <div className="flex justify-end">
                <button
                  onClick={toggleRoomGallery}
                  className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200 focus:outline-none"
                  aria-label="Close gallery"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
            </div>

            <div className="pt-12 p-4">
              <RoomGallery postId={id} roomId={selectedRoomId} />
            </div>
          </motion.div>
        </>
      )}
    </div>
  );
}

export default PostDetail;

import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../firebase";

function ChatRightSidebar({ chatId }) {
  const [conversation, setConversation] = useState(null);
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchConversationDetails = async () => {
      try {
        const conversationRef = collection(db, "conversations");
        const q = query(conversationRef, where("chatId", "==", chatId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          const conversationData = doc.data();

          const createdAt =
            conversationData.createdAt instanceof Timestamp
              ? conversationData.createdAt.toDate().toISOString()
              : conversationData.createdAt;

          setConversation({ ...conversationData, createdAt });
          fetchPostDetails(conversationData.postId);
        } else {
          console.error("No conversation found");
        }
      } catch (error) {
        console.error("Error fetching conversation details:", error);
      }
      setLoading(false);
    };

    const fetchPostDetails = async (postId) => {
      try {
        const postDocRef = doc(db, "posts", postId);
        const postDoc = await getDoc(postDocRef);
        if (postDoc.exists()) {
          setPost(postDoc.data());
        } else {
          console.error("No post found");
        }
      } catch (error) {
        console.error("Error fetching post details:", error);
      }
    };

    fetchConversationDetails();
  }, [chatId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  if (loading) {
    return (
      <div className="lg:w-1/4 w-full border-l border-gray-300 dark:border-gray-600 flex items-center justify-center">
        <p className="text-gray-500">Loading details...</p>
      </div>
    );
  }

  if (!conversation || !post) {
    return (
      <div className="lg:w-1/4 w-full border-l border-gray-600 flex items-center justify-center">
        <p className="text-gray-500">No details available</p>
      </div>
    );
  }

  return (
    <div className="lg:w-1/4 w-full border-l border-gray-300 dark:border-gray-600 p-4 bg-gray-100 dark:bg-gray-800">
      <div className="flex flex-col space-y-4">
        <div className="text-xl font-semibold text-gray-800 dark:text-gray-100 flex justify-center ">
          About this Conversation
        </div>

        <div className="bg-white dark:bg-gray-700 p-4 rounded-lg shadow">
          <h3 className="text-lg font-bold mb-2 text-gray-800 dark:text-gray-100">
            Conversation Details
          </h3>
          <div className="flex flex-col space-y-2">
            <img
              src={conversation.postImg}
              alt={conversation.postName}
              className="w-full h-32 object-cover rounded-lg"
            />
            <p className="text-sm text-gray-600 dark:text-gray-300">
              <strong>Post:</strong> {conversation.postName}
            </p>
            <p className="text-sm text-gray-600 dark:text-gray-300">
              <strong>Started on:</strong> {formatDate(conversation.createdAt)}
            </p>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-700 p-4 rounded-lg shadow">
          <h3 className="text-lg font-bold mb-2 text-gray-800 dark:text-gray-100 border-b border-gray-500">
            Post Details
          </h3>
          <div className="flex flex-col space-y-2">
            <p className="text-sm text-gray-600 dark:text-gray-300">
              <strong>Category:</strong> {post.category}
            </p>
            <p className="text-sm text-gray-600 dark:text-gray-300 line-clamp-6">
              <strong>Description:</strong> {post.description}
            </p>
            <p className="text-sm text-gray-600 dark:text-gray-300">
              <strong>Location:</strong> {post.location.name},{" "}
              {post.location.city}
            </p>
            {post.purchaseType === "Rent" && (
              <p className="text-sm text-gray-600 dark:text-gray-300">
                <strong>Rent Price:</strong>{" "}
                {new Intl.NumberFormat().format(post.rentPrice)} Birr per{" "}
                {post.rentPaymentType === "monthly" ? "month" : "year"}
              </p>
            )}

            {post.purchaseType === "sale" && (
              <p className="text-sm text-gray-600 dark:text-gray-300">
                <strong>Selling Price:</strong>{" "}
                {new Intl.NumberFormat().format(post.salePrice)} Birr
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatRightSidebar;

import React, { useEffect, useState } from "react";
import Header from "../components/common/Header";
import { motion } from "framer-motion";
import { db } from "../../firebase";
import {
  collection,
  query,
  orderBy,
  limit,
  onSnapshot,
  Timestamp,
} from "firebase/firestore";
import { CheckCircle, Clock, ClockAlert, ShoppingBag } from "lucide-react";
import StatCard from "../components/common/StatCard";
import MessagesTable from "../components/messages/MessagesTable";

function MessagesPage() {
  const [messagesStats, setMessagesStats] = useState({
    totalMessages: 0,
    pendingMessages: 0,
    inProgressMessages: 0,
    completedMessages: 0,
  });
  const [loading, setLoading] = useState(true);
  const [messagesData, setMessagesData] = useState([]);

  useEffect(() => {
    const q = query(
      collection(db, "conversations"),
      orderBy("createdAt"),
      limit(40)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      let totalMessages = 0;
      let pendingMessages = 0;
      let inProgressMessages = 0;
      let completedMessages = 0;
      const allMessages = [];

      snapshot.docs.forEach((doc) => {
        const data = doc.data();

        const createdAt =
          data.createdAt instanceof Timestamp
            ? data.createdAt.toDate().toISOString()
            : data.createdAt;

        const updatedAt =
          data.updatedAt instanceof Timestamp
            ? data.updatedAt.toDate().toISOString()
            : data.updatedAt;

        allMessages.push({
          id: doc.id,
          ...data,
          createdAt,
          updatedAt,
        });

        totalMessages += 1;
        if (data.status === "pending") {
          pendingMessages += 1;
        } else if (data.status === "inProgress") {
          inProgressMessages += 1;
        } else if (data.status === "completed") {
          completedMessages += 1;
        }
      });

      setMessagesStats({
        totalMessages,
        pendingMessages,
        inProgressMessages,
        completedMessages,
      });

      setMessagesData(allMessages);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="flex-1 relative z-10 overflow-auto">
      <Header title={"Messages"} />

      <main className="max-w-7xl mx-auto py-6 px-4 lg:px-8">
        <motion.div
          className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <StatCard
            name="Total Messages"
            icon={ShoppingBag}
            value={messagesStats.totalMessages}
            color="#6366F1"
          />
          <StatCard
            name="Pending Messages"
            icon={ClockAlert}
            value={messagesStats.pendingMessages}
            color="#ff0000"
          />
          <StatCard
            name="In Progress Messages"
            icon={Clock}
            value={messagesStats.inProgressMessages}
            color="#F59E0B"
          />
          <StatCard
            name="Completed Messages"
            icon={CheckCircle}
            value={messagesStats.completedMessages}
            color="#10B981"
          />
        </motion.div>

        <div className="mt-8">
          {loading ? (
            <p>Loading messages...</p>
          ) : (
            <MessagesTable messagesData={messagesData} />
          )}
        </div>
      </main>
    </div>
  );
}

export default MessagesPage;

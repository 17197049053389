import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import Loader from "../common/Loader/Loader";
import * as PANOLENS from "panolens";

function SurroundingSphereView() {
  const { postId } = useParams();
  const [postData, setPostData] = useState(null);
  const [loading, setLoading] = useState(true);
  const panoramaRef = useRef(null);

  useEffect(() => {
    const fetchPostData = async () => {
      try {
        const postRef = doc(db, "posts", postId);
        const postDoc = await getDoc(postRef);

        if (postDoc.exists()) {
          setPostData(postDoc.data());
        } else {
          console.error("No such post!");
        }
      } catch (error) {
        console.error("Error fetching post data: ", error);
      }
      setLoading(false);
    };

    fetchPostData();
  }, [postId]);

  useEffect(() => {
    if (postData?.surrounding?.panoramaImage) {
      const panorama = new PANOLENS.ImagePanorama(
        postData.surrounding.panoramaImage
      );
      const viewer = new PANOLENS.Viewer({
        container: panoramaRef.current,
        autoRotate: true,
        autoRotateSpeed: 0.5,
        controlBar: true,
      });

      viewer.add(panorama);

      return () => {
        viewer.dispose();
      };
    }
  }, [postData]);

  if (loading) {
    return <Loader />;
  }

  if (!postData) {
    return (
      <div className="h-screen text-center text-xl font-bold py-20 flex justify-center items-center dark:text-gray-200 text-black">
        Post not found
      </div>
    );
  }

  return (
    <div className="max-w-7xl mt-20 mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4 text-gray-900 dark:text-gray-100">
        3D Tour of the Surrounding
      </h1>

      {postData.surrounding?.panoramaImage ? (
        <div className="flex justify-center items-center">
          <div className="w-full lg:w-2/3 h-420" ref={panoramaRef} />
        </div>
      ) : (
        <p className="text-gray-500 dark:text-gray-400 h-60 flex justify-center items-center">
          No surrounding images available.
        </p>
      )}
    </div>
  );
}

export default SurroundingSphereView;

import React, { useState } from "react";
import Header from "../components/common/Header";
import RoomForm from "../components/createPost/RoomsForm";
import SurroundingOutsideForm from "../components/createPost/SurroundngOutsideForm";
import LocationSelector from "../components/createPost/LocationSelector";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";
import CurrencyInput from "react-currency-input-field";
import { TailSpin } from "react-loader-spinner";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function CreatePostPage({ currentUser, firstName, lastName }) {
  const [propertyData, setPropertyData] = useState({
    name: "",
    category: "Apartments",
    purchaseType: "Rent",
    rentPaymentType: "",
    rentPrice: "",
    salePrice: "",
    rooms: [],
    outside: {
      normalImages: [],
      panoramaImage: "",
    },
    surrounding: {
      normalImages: [],
      panoramaImage: "",
    },
    location: {
      name: "",
      city: "",
      coordinates: { lat: "", long: "" },
    },
    description: "",
    propertyOwner: {
      name: "",
      phone: "",
    },
  });

  const [loading, setLoading] = useState(false);

  const categories = [
    "Apartments",
    "Hotels",
    "Condominiums",
    "Offices & Shops",
    "Residence",
    "Villa",
    "Real Estate",
    "Warehouse",
    "Factory/Industry",
    "Guest House",
    "Event venue",
  ];

  const nextRoomId = propertyData.rooms.length + 1;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPropertyData({ ...propertyData, [name]: value });
  };

  const handleRoomChange = (roomIndex, updatedRoom) => {
    const updatedRooms = propertyData.rooms.map((room, index) =>
      index === roomIndex ? updatedRoom : room
    );
    setPropertyData({ ...propertyData, rooms: updatedRooms });
  };

  const addRoom = () => {
    setPropertyData({
      ...propertyData,
      rooms: [
        ...propertyData.rooms,
        {
          roomId: nextRoomId,
          roomType: "",
          roomInstancesCount: 0,
          instances: [],
        },
      ],
    });
  };

  const removeRoom = (roomIndex) => {
    const updatedRooms = propertyData.rooms.filter(
      (_, index) => index !== roomIndex
    );
    setPropertyData({ ...propertyData, rooms: updatedRooms });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const name = firstName + " " + lastName;

    try {
      const post = {
        ...propertyData,
        createdAt: new Date().toISOString(),
        postedBy: {
          name: name || "",
          employeeId: currentUser.uid,
        },
      };

      const docRef = await addDoc(collection(db, "posts"), post);
      console.log("Document written with ID: ", docRef.id);

      setPropertyData({
        name: "",
        category: "Apartments",
        purchaseType: "Rent",
        rentPaymentType: "",
        rentPrice: "",
        salePrice: "",
        rooms: [],
        outside: {
          normalImages: [],
          panoramaImage: "",
        },
        surrounding: {
          normalImages: [],
          panoramaImage: "",
        },
        location: {
          name: "",
          city: "",
          coordinates: { lat: "", long: "" },
        },
        description: "",
        propertyOwner: {
          name: "",
          phone: "",
        },
      });
      setLoading(false);
      toast.success("Post Created Succesfully");
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <div className="flex-1 overflow-auto relative z-10 text-white">
      <Header title="Create Post" />
      <main className="max-w-6xl mx-auto py-6 px-4 lg:px-8">
        <form className="space-y-6">
          <div>
            <label
              htmlFor="name"
              className="block text-lg font-medium text-gray-200"
            >
              Property Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={propertyData.name}
              onChange={handleInputChange}
              className="mt-1 block w-full p-2 border border-gray-700 bg-gray-800 text-white rounded-md"
              placeholder="Enter property name"
            />
          </div>

          <div>
            <label
              htmlFor="category"
              className="block text-lg font-medium text-gray-200"
            >
              Category
            </label>
            <select
              name="category"
              id="category"
              value={propertyData.category}
              onChange={handleInputChange}
              className="mt-1 block w-full p-2 border border-gray-700 bg-gray-800 text-white rounded-md"
            >
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>

          {/* Purchase Type */}
          <div>
            <label className="block text-lg font-medium text-gray-200">
              Purchase Type
            </label>
            <div className="flex space-x-4">
              <label>
                <input
                  type="radio"
                  name="purchaseType"
                  value="Rent"
                  checked={propertyData.purchaseType === "Rent"}
                  onChange={handleInputChange}
                />
                Rent
              </label>
              <label>
                <input
                  type="radio"
                  name="purchaseType"
                  value="Sale"
                  checked={propertyData.purchaseType === "Sale"}
                  onChange={handleInputChange}
                />
                Sale
              </label>
            </div>
          </div>

          {propertyData.purchaseType === "Rent" ? (
            <>
              <div>
                <label className="block text-lg font-medium text-gray-200">
                  Rent Payment Type
                </label>
                <div className="flex space-x-4">
                  <label>
                    <input
                      type="radio"
                      name="rentPaymentType"
                      value="monthly"
                      checked={propertyData.rentPaymentType === "monthly"}
                      onChange={handleInputChange}
                    />
                    Monthly
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="rentPaymentType"
                      value="yearly"
                      checked={propertyData.rentPaymentType === "yearly"}
                      onChange={handleInputChange}
                    />
                    Yearly
                  </label>
                </div>
              </div>
              <div>
                <label
                  htmlFor="rentPrice"
                  className="block text-lg font-medium text-gray-200"
                >
                  Rent Price
                </label>
                <CurrencyInput
                  name="rentPrice"
                  id="rentPrice"
                  value={propertyData.rentPrice}
                  decimalsLimit={2}
                  onValueChange={(value) =>
                    setPropertyData({ ...propertyData, rentPrice: value })
                  }
                  className="mt-1 block w-full p-2 border border-gray-700 bg-gray-800 text-white rounded-md"
                  placeholder="Enter rent price"
                  prefix="Birr "
                  intlConfig={{ locale: "en-ET", currency: "ETB" }}
                />
              </div>
            </>
          ) : (
            <div>
              <label
                htmlFor="salePrice"
                className="block text-lg font-medium text-gray-200"
              >
                Sale Price
              </label>
              <CurrencyInput
                name="salePrice"
                id="salePrice"
                value={propertyData.salePrice}
                decimalsLimit={2}
                onValueChange={(value) =>
                  setPropertyData({ ...propertyData, salePrice: value })
                }
                className="mt-1 block w-full p-2 border border-gray-700 bg-gray-800 text-white rounded-md"
                placeholder="Enter sale price"
                prefix="Birr "
                intlConfig={{ locale: "en-ET", currency: "ETB" }}
              />
            </div>
          )}

          {/* Add Room Section */}
          <div className="p-4 border rounded-xl border-gray-500">
            <label className="block text-lg font-medium mb-4 text-gray-200">
              Rooms
            </label>
            {propertyData.rooms.map((room, index) => (
              <div key={room.roomId} className="relative">
                <RoomForm
                  room={room}
                  onChange={(updatedRoom) =>
                    handleRoomChange(index, updatedRoom)
                  }
                />
                <button
                  type="button"
                  onClick={() => removeRoom(index)}
                  className="absolute -top-4 right-0 bg-red-500 text-white rounded p-2"
                >
                  Remove Room
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={addRoom}
              className="mt-2 p-2 bg-blue-500 text-white rounded"
            >
              Add Room
            </button>
          </div>

          <div className="p-4 border rounded-xl border-gray-500">
            <label className="block text-lg font-medium mb-4 text-gray-200">
              Outside Images
            </label>
            <SurroundingOutsideForm
              outside={propertyData.outside}
              onChange={(updatedOutside) =>
                setPropertyData({ ...propertyData, outside: updatedOutside })
              }
            />
          </div>

          <div className="p-4 border rounded-xl border-gray-500 mt-6">
            <label className="block text-lg font-medium mb-4 text-gray-200">
              Surrounding Images
            </label>
            <SurroundingOutsideForm
              outside={propertyData.surrounding}
              onChange={(updatedSurrounding) =>
                setPropertyData({
                  ...propertyData,
                  surrounding: updatedSurrounding,
                })
              }
            />
          </div>

          <div className="mt-6">
            <h2 className="text-lg font-medium text-gray-200">Location</h2>
            <LocationSelector
              location={propertyData.location}
              onChange={(updatedLocation) =>
                setPropertyData({ ...propertyData, location: updatedLocation })
              }
            />
          </div>

          {/* Description Input */}
          <div className="mb-10">
            <label
              htmlFor="description"
              className="block text-lg font-medium text-gray-200"
            >
              Description
            </label>
            <ReactQuill
              theme="snow"
              value={propertyData.description}
              onChange={(value) =>
                setPropertyData({ ...propertyData, description: value })
              }
              className="mt-1 block w-full bg-gray-800 text-white rounded-md"
              placeholder="Enter property description"
              modules={{
                toolbar: [
                  [{ header: "1" }, { header: "2" }, { font: [] }],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["bold", "italic", "underline"],
                  [{ align: [] }],
                  ["link", "image"],
                ],
              }}
            />
          </div>

          {/* Property Owner Inputs */}
          <div>
            <label
              htmlFor="propertyOwnerName"
              className="block text-lg font-medium text-gray-200"
            >
              Property Owner Name
            </label>
            <input
              type="text"
              name="propertyOwnerName"
              id="propertyOwnerName"
              value={propertyData.propertyOwner.name}
              onChange={(e) =>
                setPropertyData({
                  ...propertyData,
                  propertyOwner: {
                    ...propertyData.propertyOwner,
                    name: e.target.value,
                  },
                })
              }
              className="mt-1 block w-full p-2 border border-gray-700 bg-gray-800 text-white rounded-md"
              placeholder="Enter owner name"
            />
          </div>

          <div>
            <label
              htmlFor="propertyOwnerPhone"
              className="block text-lg font-medium text-gray-200"
            >
              Property Owner Phone
            </label>
            <input
              type="tel"
              name="propertyOwnerPhone"
              id="propertyOwnerPhone"
              value={propertyData.propertyOwner.phone}
              onChange={(e) =>
                setPropertyData({
                  ...propertyData,
                  propertyOwner: {
                    ...propertyData.propertyOwner,
                    phone: e.target.value,
                  },
                })
              }
              className="mt-1 block w-full p-2 border border-gray-700 bg-gray-800 text-white rounded-md"
              placeholder="Enter owner phone number"
            />
          </div>

          {/* Submit Button */}
          <div>
            <button
              onClick={handleSubmit}
              disabled={loading}
              type="submit"
              className="w-full p-2 bg-mainColor text-white rounded"
            >
              {loading ? (
                <div className="flex justify-center items-center text-center text-white space-x-3">
                  <TailSpin
                    visible={loading}
                    height="20"
                    width="20"
                    color="#00cafc"
                    ariaLabel="loading"
                  />{" "}
                  <span>Creating...</span>
                </div>
              ) : (
                "Create Post"
              )}
            </button>
          </div>
        </form>
      </main>
    </div>
  );
}

export default CreatePostPage;

import React, { useState } from "react";
import Apartments from "../../assets/image/Apartments.jpeg";
import Hotels from "../../assets/image/Hotels.jpg";
import Condos from "../../assets/image/Condos.jpeg";
import Offices from "../../assets/image/OfficesnShops.png";
import Residence from "../../assets/image/Residence.jpg";
import Villa from "../../assets/image/Villa.jpg";
import RealEstate from "../../assets/image/RealEstate.jpeg";
import Warehouse from "../../assets/image/Warehouse.jpg";
import Factory from "../../assets/image/Factory.jpg";
import GuestHouse from "../../assets/image/GuestHouse.jpg";
import Event from "../../assets/image/Event.jpg";

const Catagories = [
  { title: "Apartments", image: Apartments },
  { title: "Hotels", image: Hotels },
  { title: "Guest House", image: GuestHouse },
  { title: "Condominiums", image: Condos },
  { title: "Offices & Shops", image: Offices },
  { title: "Residence", image: Residence },
  { title: "Villa", image: Villa },
  { title: "Real Estate", image: RealEstate },
  { title: "Warehouse", image: Warehouse },
  { title: "Factory/Industry", image: Factory },
  { title: "Event venue", image: Event },
];

const FilterBar = ({ onFilterChange }) => {
  const [selectedFilter, setSelectedFilter] = useState(null);

  const handleFilterClick = (title) => {
    setSelectedFilter(title);
    onFilterChange(title);
  };

  return (
    <div className="lg:flex lg:flex-col lg:space-y-4 lg:pr-16 lg:py-8 lg:h-full dark:border-gray-600">
      <div className="flex space-x-4 py-1 lg:space-x-0 lg:space-y-6 lg:py-0 lg:flex-col lg:items-start">
        <p className="lg:block hidden text-gray-700 dark:text-gray-300">
          Select Catagory
        </p>
        {Catagories.map((filter) => (
          <button
            key={filter.title}
            onClick={() => handleFilterClick(filter.title)}
            className={`${
              selectedFilter === filter.title
                ? "bg-mainColor text-white"
                : "bg-transparent text-gray-700 hover:text-gray-700 hover:bg-sky-50 dark:text-gray-300 dark:ring-gray-600 dark:hover:ring-gray-400 dark:hover:bg-gray-700"
            } w-full lg:w-64 lg:h-[4.5rem] border border-gray-300 dark:border-gray-700 lg:px-0 lg:py-0 pr-5 text-base font-medium rounded-[15px] select-none whitespace-nowrap lg:whitespace-normal flex items-center shadow-md`}
          >
            <div className="h-full lg:w-1/3 w-20">
              <img
                src={filter.image}
                alt={filter.title}
                className="object-cover h-full w-full rounded-l-[15px]"
              />
            </div>

            <div className="flex-grow pl-4 text-left">
              <span>{filter.title}</span>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default FilterBar;

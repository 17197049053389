import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  startAt,
  endAt,
} from "firebase/firestore";
import { db } from "../firebase";
import PostCard from "../components/posts/PostCard";
import ScrollToTopButton from "../components/common/ScrollToTopButton/ScrollToTopButton";
import Loader from "../components/common/Loader/Loader";

const SearchPage = () => {
  const { searchWord } = useParams();
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState(searchWord || "");

  useEffect(() => {
    if (searchWord) {
      const capitalized =
        searchWord.charAt(0).toUpperCase() + searchWord.slice(1);
      setSearchTerm(capitalized);
    }
  }, [searchWord]);

  useEffect(() => {
    const fetchFilteredPosts = async () => {
      setLoading(true);
      setError(null);

      try {
        const postsRef = collection(db, "posts");

        const nameQuery = query(
          postsRef,
          orderBy("name"),
          startAt(searchTerm),
          endAt(searchTerm + "\uf8ff")
        );

        const categoryQuery = query(
          postsRef,
          orderBy("category"),
          startAt(searchTerm),
          endAt(searchTerm + "\uf8ff")
        );

        const descriptionQuery = query(
          postsRef,
          orderBy("description"),
          startAt(searchTerm),
          endAt(searchTerm + "\uf8ff")
        );

        const cityQuery = query(
          postsRef,
          orderBy("location.city"),
          startAt(searchTerm),
          endAt(searchTerm + "\uf8ff")
        );

        const locationNameQuery = query(
          postsRef,
          orderBy("location.name"),
          startAt(searchTerm),
          endAt(searchTerm + "\uf8ff")
        );

        const [
          categorySnapshot,
          descriptionSnapshot,
          nameSnapshot,
          citySnapshot,
          locationNameSnapshot,
        ] = await Promise.all([
          getDocs(categoryQuery),
          getDocs(descriptionQuery),
          getDocs(nameQuery),
          getDocs(cityQuery),
          getDocs(locationNameQuery),
        ]);

        const categoryPosts = categorySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const descriptionPosts = descriptionSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const namePosts = nameSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const cityPosts = citySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const locationNamePosts = locationNameSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const combinedPosts = [
          ...categoryPosts,
          ...descriptionPosts,
          ...namePosts,
          ...cityPosts,
          ...locationNamePosts,
        ];

        // Remove duplicates by using Map
        const uniquePosts = Array.from(
          new Map(combinedPosts.map((post) => [post.id, post])).values()
        );

        setFilteredPosts(uniquePosts);
      } catch (error) {
        console.error("Error fetching posts:", error);
        setError("Failed to load posts. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    if (searchTerm) {
      fetchFilteredPosts();
    } else {
      setFilteredPosts([]);
      setLoading(false);
    }
  }, [searchTerm]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="font-Quicksand pt-10 overflow-hidden">
      <div className="relative z-10">
        <div className="text-center mt-14 mb-10">
          <h1 className="text-xl font-bold text-gray-700 dark:text-gray-200">
            {searchWord && `Searching for ${searchWord}`}
          </h1>
        </div>
      </div>

      <div className="py-10 px-5 lg:px-10 z-10">
        {error ? (
          <p className="text-center text-red-500">{error}</p>
        ) : (
          <>
            {filteredPosts.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {filteredPosts.map((post) => (
                  <PostCard key={post.id} post={post} />
                ))}
              </div>
            ) : (
              <p className="text-center font-semibold flex justify-center items-center h-420 text-gray-500 dark:text-gray-400">
                No {searchWord} posts found.
              </p>
            )}
          </>
        )}
      </div>

      <ScrollToTopButton />
    </div>
  );
};

export default SearchPage;

import React from "react";
import ContactInfo from "../components/profile/contact-info/ContactInfo";
import PostManage from "../components/profile/post-manage/PostManage";
import { useAuth } from "../context/AuthContext";
const Profile = () => {
  const { currentUser, loading } = useAuth();
  return (
    <div className="max-w-4xl mx-auto p-5 text-gray-800">
      <ContactInfo currentUser={currentUser} loading={loading} />
      <PostManage currentUser={currentUser} loading={loading} />
    </div>
  );
};

export default Profile;

import React, { useRef, useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  where,
} from "firebase/firestore";
import { useAuth } from "../context/AuthContext";
import homeBg from "../assets/image/homeBg.jpg";
import ScrollToTopButton from "../components/common/ScrollToTopButton/ScrollToTopButton";
import LoginModal from "../components/common/login-modal/LoginModal";
import { Link, useNavigate } from "react-router-dom";
import PostCard from "../components/posts/PostCard";
import { db } from "../firebase";
import FilterBar from "../components/FilterBar/FilterBar";
import { TailSpin } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { currentUser } = useAuth();
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const filterRef = useRef(null);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleSearch = () => {
    if (selectedCategory) {
      navigate(`/search/${selectedCategory}`);
    } else {
      navigate("/explore");
    }
  };

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const postsCollection = collection(db, "posts");
      const postsQuery = query(
        postsCollection,
        orderBy("createdAt", "desc"),
        limit(8)
      );
      const postsSnapshot = await getDocs(postsQuery);
      const postsList = postsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPosts(postsList);
      setFilteredPosts(postsList);
      setLoading(false);
    };

    fetchPosts();
  }, []);

  const filterPostsByCategory = async (category) => {
    if (category) {
      setLoading(true);
      const postsCollection = collection(db, "posts");
      const postsQuery = query(
        postsCollection,
        where("category", "==", category),
        orderBy("createdAt", "desc"),
        limit(8)
      );
      const postsSnapshot = await getDocs(postsQuery);
      const filtered = postsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFilteredPosts(filtered);
      setLoading(false);
    } else {
      setFilteredPosts(posts);
      setLoading(false);
    }
  };

  const handleFilterChange = (category) => {
    setSelectedCategory(category);
    filterPostsByCategory(category);
  };

  const showLoginModal = () => {
    setIsLoginModalVisible(true);
  };

  const handleScroll = () => {
    const filterContainer = filterRef.current;
    const maxScrollLeft =
      filterContainer.scrollWidth - filterContainer.clientWidth;

    const scrollTolerance = 5;

    setShowLeftButton(filterContainer.scrollLeft > 0);
    setShowRightButton(
      filterContainer.scrollLeft < maxScrollLeft - scrollTolerance
    );
  };

  const scrollLeft = () => {
    filterRef.current.scrollBy({
      left: -200,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    filterRef.current.scrollBy({
      left: 200,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    handleScroll();
  }, []);

  return (
    <div className="font-Quicksand pt-20 overflow-hidden">
      <img
        src={homeBg}
        alt="background"
        className="fixed w-full h-4/6 object-cover top-0 left-0 select-none brightness-50"
      />
      <div className="relative z-10">
        {currentUser ? (
          <div className="text-center mt-14 mb-28">
            <h1 className="text-4xl font-bold text-gray-300 dark:text-gray-200">
              {t("welcome_back")}
            </h1>
            <p className="mt-4 font-medium text-lg text-white dark:text-gray-400">
              {t("ready_to_explore_your_next_home")}
            </p>
          </div>
        ) : (
          <div className="text-center mt-10 lg:mt-14 mb-10 w-full">
            <h1 className="text-2xl font-bold text-gray-100">
              Find your{" "}
              <span className="text-white bg-mainColor p-1 sm:p-2 rounded-lg select-none inline-block sm:inline">
                Dream Home
              </span>
            </h1>
            <p className="mt-4 mx-5 font-medium text-base text-gray-100">
              Discover the perfect home or apartment. Any location. Any size.
              Any price range.
            </p>
            <div className="mt-8 sm:mt-10 flex flex-col sm:flex-row justify-center items-center select-none">
              <button
                onClick={showLoginModal}
                className="bg-mainColor text-white font-semibold hover:bg-hovermainColor transition-colors py-2 w-3/4 sm:w-48 rounded-full mx-0 sm:mx-2 mb-4 sm:mb-0"
              >
                {t("login")}
              </button>
              <LoginModal
                isLoginModalVisible={isLoginModalVisible}
                setIsLoginModalVisible={setIsLoginModalVisible}
              />
              <Link
                to="/register"
                className="border-2 border-mainColor text-mainColor font-semibold bg-transparent hover:bg-hovermainColor hover:text-white hover:border-hovermainColor transition-colors py-2 w-3/4 sm:w-48 rounded-full mx-0 sm:mx-2"
              >
                {t("signup")}
              </Link>
            </div>
          </div>
        )}
      </div>

      <div
        className={`relative bg-backgroundColor dark:bg-backgroundDark transition-colors duration-300 py-5 px-5 lg:px-10 lg:py-0 z-10`}
      >
        <div className="grid grid-cols-1 lg:grid-cols-4 ">
          <div className="relative lg:mt-5 mb-4 lg:px-3">
            {showLeftButton && (
              <div className="lg:hidden absolute flex items-center inset-y-0 -left-5 lg:left-8 lg:w-24 w-20 bg-gradient-to-r from-white dark:from-gray-800 via-white dark:via-gray-800 to-transparent">
                <button
                  className="absolute flex justify-end left-[1rem] lg:left-5 text-center bg-gray-300 dark:bg-gray-600 border border-gray-300 dark:border-gray-600 p-1 rounded-full text-gray-600 dark:text-gray-300 text-sm hover:text-gray-700 dark:hover:text-gray-200 hover:cursor-pointer"
                  onClick={scrollLeft}
                >
                  <FaChevronLeft />
                </button>
              </div>
            )}

            {showRightButton && (
              <div className="lg:hidden absolute flex items-center inset-y-0 -right-[0.1rem] lg:right-4 lg:w-28 w-10 bg-gradient-to-l from-white dark:from-gray-800 via-white dark:via-gray-800 to-transparent">
                <button
                  className="absolute flex justify-end -right-2 lg:right-4 text-center bg-gray-300 dark:bg-gray-600 border border-gray-300 dark:border-gray-600 p-1 rounded-full text-gray-600 dark:text-gray-300 text-sm hover:text-gray-700 dark:hover:text-gray-200 hover:cursor-pointer"
                  onClick={scrollRight}
                >
                  <FaChevronRight />
                </button>
              </div>
            )}

            <div className="flex justify-start items-center space-x-2">
              <div
                className="pr-7 lg:pl-0 overflow-x-auto no-scrollbar"
                onScroll={handleScroll}
                ref={filterRef}
              >
                <FilterBar onFilterChange={handleFilterChange} />
              </div>
            </div>
          </div>
          <div className="lg:col-span-3 mt-2 lg:mt-5 space-y-4 lg:overflow-y-auto lg:h-[120vh] rounded-lg no-scrollbar">
            <h3 className="text-xl lg:ml-5 lg:mt-5 text-gray-500 dark:text-gray-300 font-medium select-none">
              {selectedCategory ? <div>{selectedCategory}</div> : "Featured"}
            </h3>
            {loading ? (
              <div className="flex justify-center items-center py-32 lg:h-2/3 lg:py-0">
                <TailSpin
                  visible={loading}
                  height="50"
                  width="50"
                  color="#00a3cc"
                  ariaLabel="loading"
                />
              </div>
            ) : (
              <>
                {filteredPosts.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-7 mx-0">
                    {filteredPosts.map((post) => (
                      <PostCard key={post.id} post={post} />
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500 dark:text-gray-300 flex justify-center text-center py-32">
                    No posts available.
                  </p>
                )}
              </>
            )}
          </div>
        </div>
        <div className="justify-center items-center w-full text-center mt-5 pb-5">
          <button
            onClick={handleSearch}
            className="flex items-center space-x-1 mx-auto mt-4 bg-mainColor text-white hover:bg-hovermainColor py-2 px-6 sm:px-4 rounded-full transition-colors"
          >
            <span>See More </span>{" "}
            {selectedCategory && <div>{selectedCategory}</div>}
          </button>
        </div>
      </div>
      <ScrollToTopButton />
    </div>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";

function ChatRightSidebar({ conversation }) {
  const [postData, setPostData] = useState(null);

  useEffect(() => {
    const fetchPostData = async () => {
      if (conversation.postId) {
        try {
          const postRef = doc(db, "posts", conversation.postId);
          const postSnap = await getDoc(postRef);

          if (postSnap.exists()) {
            setPostData(postSnap.data());
          } else {
            console.log("No such post document!");
          }
        } catch (error) {
          console.error("Error fetching post data: ", error);
        }
      }
    };

    fetchPostData();
  }, [conversation.postId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  if (!postData) {
    return (
      <div className="h-screen flex justify-center items-center">
        Post data Not found
      </div>
    );
  }

  return (
    <div className="p-6 h-full rounded-lg text-gray-300 bg-gray-800 space-y-6 overflow-y-scroll no-scrollbar">
      {conversation.employeeName && (
        <p className="flex justify-center items-center text-yellow-500">
          Chat is Assigned to {conversation.employeeName}
        </p>
      )}
      <h1 className="text-base font-semibold text-gray-100 bg-gray-700 w-full rounded-lg p-3 flex justify-center">
        About the Post
      </h1>

      <h2 className="text-3xl font-extrabold mb-6 border-b border-gray-600 pb-3">
        {postData.name}
      </h2>

      <div className="space-y-4">
        <div className="text-lg">
          <span className="font-semibold text-gray-400">Category:</span>{" "}
          <span className="text-base">{postData.category}</span>
        </div>

        <div className="text-lg">
          <span className="font-semibold text-gray-400">Location:</span>{" "}
          <span className="text-base">
            {postData.location.name}, {postData.location.city}
          </span>
        </div>

        <div className="text-lg">
          <span className="font-semibold text-gray-400">Posted By:</span>{" "}
          <span className="text-base">{postData.postedBy.name}</span>
        </div>

        <div className="text-lg py-10 px-3 bg-gray-700 rounded-lg">
          <span className="font-bold text-white">Owner Name:</span>{" "}
          <span className="text-base">{postData.propertyOwner.name}</span>{" "}
          <br />
          <span className="font-bold text-white">Phone Number:</span>{" "}
          <span className="text-base">{postData.propertyOwner.phone}</span>
        </div>

        <div className="text-lg">
          <span className="font-semibold text-gray-400">Purchase Type:</span>{" "}
          <span className="text-base"> For {postData.purchaseType}</span>
        </div>

        {postData.purchaseType === "Rent" && (
          <div className="text-lg">
            <span className="font-semibold text-gray-400">Rent Price:</span>{" "}
            <span className="text-base">
              {new Intl.NumberFormat().format(postData.rentPrice)} ETB (
              {postData.rentPaymentType})
            </span>
          </div>
        )}

        {postData.purchaseType === "Sale" && (
          <div className="text-lg">
            <span className="font-semibold text-gray-400">Selling Price:</span>{" "}
            <span className="text-base">
              {new Intl.NumberFormat().format(postData.salePrice)} ETB
            </span>
          </div>
        )}

        <div className="text-lg">
          <span className="font-semibold text-gray-400 ">Description:</span>{" "}
          <span className="text-base mx-4">{postData.description}</span>
        </div>

        <div className="text-lg">
          <span className="font-semibold text-gray-400">Posted On:</span>{" "}
          <span className="text-base">{formatDate(postData.createdAt)}</span>
        </div>
      </div>
    </div>
  );
}

export default ChatRightSidebar;

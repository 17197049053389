import React from "react";
import { Link } from "react-router-dom";
import { IoWarningOutline } from "react-icons/io5";

function ErrorPage() {
  return (
    <div className="flex flex-col items-center justify-center h-fit lg:py-32 py-32 px-6 sm:px-10 bg-transparent font-Quicksand select-none">
      <IoWarningOutline className="text-red-500 text-6xl sm:text-8xl mb-4" />
      <h1 className="text-3xl sm:text-4xl font-bold text-gray-800 dark:text-white mb-4 text-center">
        404 - Page Not Found
      </h1>
      <p className="text-base sm:text-lg text-gray-600 dark:text-gray-300 mb-6 text-center">
        Oops! The page you're looking for doesn't exist or has been moved.
      </p>
      <Link
        to="/"
        className="bg-mainColor text-white font-semibold hover:bg-hovermainColor transition-colors py-2 px-6 sm:px-8 rounded-full text-sm sm:text-base"
      >
        Back to Home
      </Link>
    </div>
  );
}

export default ErrorPage;

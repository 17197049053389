import React, { useEffect, useState } from "react";
import Header from "../components/common/Header";
import { motion } from "framer-motion";
import { db } from "../../firebase";
import {
  collection,
  query,
  orderBy,
  limit,
  onSnapshot,
  Timestamp,
} from "firebase/firestore";
import { CheckCircle, Clock, ClockAlert, ShoppingBag } from "lucide-react";
import StatCard from "../components/common/StatCard";
import ContactsTable from "../components/contacts/ContactsTable";

function ContactsPage() {
  const [loading, setLoading] = useState(true);
  const [contactsData, setContactsData] = useState([]);
  const [totalContacts, setTotalContacts] = useState(0);

  useEffect(() => {
    const q = query(
      collection(db, "contacts"),
      orderBy("createdAt"),
      limit(40)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      let totalContact = 0;
      const allContacts = [];

      snapshot.docs.forEach((doc) => {
        const data = doc.data();
        const createdAt =
          data.createdAt instanceof Timestamp
            ? data.createdAt.toDate().toISOString()
            : data.createdAt;

        const updatedAt =
          data.updatedAt instanceof Timestamp
            ? data.updatedAt.toDate().toISOString()
            : data.updatedAt;

        allContacts.push({
          id: doc.id,
          ...data,
          createdAt,
          updatedAt,
        });

        totalContact += 1;
      });

      setTotalContacts(totalContact);
      setContactsData(allContacts);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="flex-1 relative z-10 overflow-auto">
      <Header title={"Contact Messages"} />

      <main className="max-w-7xl mx-auto py-6 px-4 lg:px-8">
        <motion.div
          className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <StatCard
            name="Total Contacts"
            icon={ShoppingBag}
            value={totalContacts}
            color="#6366F1"
          />
        </motion.div>
        <div className="mt-8">
          <ContactsTable contactsData={contactsData} loading={loading} />
        </div>
      </main>
    </div>
  );
}

export default ContactsPage;

import React, { useEffect, useState } from "react";
import { auth, googleProvider, db } from "../../../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  linkWithCredential,
  GoogleAuthProvider,
} from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { FaGoogle } from "react-icons/fa";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/image/logo.png";
import backg from "../../../assets/image/background.jpg";
import { GrHomeRounded } from "react-icons/gr";
import { TailSpin } from "react-loader-spinner";

function Registration() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailloading, setEmailloading] = useState(false);
  const [googleloading, setGoogleloading] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });
  const [showPassword, setShowPassword] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleEmailPasswordRegistration = async (e) => {
    e.preventDefault();
    setEmailloading(true);
    setMessage({ type: "", text: "" });

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      await setDoc(doc(db, "users", user.uid), {
        firstName,
        lastName,
        email: user.email,
        role: "user",
        createdAt: new Date().toISOString(),
        lastLogin: new Date().toISOString(),
      });

      setMessage({ type: "success", text: "Registration successful!" });
      navigate("/");
    } catch (error) {
      console.error(error);
      setMessage({ type: "error", text: error.message });
    } finally {
      setEmailloading(false);
    }
  };

  const handleGoogleRegistration = async () => {
    setGoogleloading(true);
    setMessage({ type: "", text: "" });

    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      const userRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(userRef);

      if (docSnap.exists()) {
        if (
          user.providerData.some(
            (provider) => provider.providerId === "google.com"
          )
        ) {
          setMessage({
            type: "success",
            text: "You are already registered with Google!",
          });
        } else {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          await linkWithCredential(user, credential);
          setMessage({
            type: "success",
            text: "Google account successfully linked to your existing account!",
          });
        }
      } else {
        await setDoc(doc(db, "users", user.uid), {
          firstName: user.displayName?.split(" ")[0] || "",
          lastName: user.displayName?.split(" ")[1] || "",
          email: user.email,
          role: "user",
          createdAt: new Date().toISOString(),
          lastLogin: new Date().toISOString(),
        });

        setMessage({
          type: "success",
          text: "Registration successful with Google!",
        });
      }

      navigate("/");
    } catch (error) {
      console.error(error);
      setMessage({ type: "error", text: error.message });
    } finally {
      setGoogleloading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <section className="relative h-fit ">
      <div className="absolute top-5 left-5 z-20">
        <button
          onClick={() => navigate("/")}
          className="fixed px-4 py-2 flex space-x-1 justify-center items-center text-sm text-black bg-gray-200 rounded-2xl hover:bg-gray-300 dark:text-white dark:bg-gray-700 dark:hover:bg-gray-600"
        >
          <GrHomeRounded />{" "}
          <span className="lg:text-sm text-xs">Back to Home</span>
        </button>
      </div>
      <div
        className="absolute inset-0 bg-cover bg-center brightness-50"
        style={{ backgroundImage: `url(${backg})` }}
      ></div>
      <div className="flex flex-col items-center justify-center px-6 py-16 lg:pb-10 lg:pt-5 mx-auto h-full relative z-10">
        <div className="flex justify-center items-center mb-5 lg:mb-2">
          <img src={logo} alt="" className="h-16" />
          <p className="font-semibold text-gray-700 dark:text-gray-300 text-2xl">
            Welcome to Find Homes
          </p>
        </div>
        <div className="w-full border border-gray-400 bg-backgroundColor dark:bg-backgroundDark dark:border-gray-600 rounded-alg max-w-lg xl:p-0">
          <div className="p-6 lg:m-5 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">
              Register your account
            </h1>

            {message.text && (
              <div
                className={`mb-4 p-3 rounded ${
                  message.type === "success"
                    ? "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-100"
                    : message.type === "info"
                    ? "bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-100"
                    : "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-100"
                }`}
              >
                {message.text}
              </div>
            )}

            <form
              className="space-y-4 md:space-y-6"
              onSubmit={handleEmailPasswordRegistration}
            >
              <div>
                <label
                  htmlFor="firstName"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  First Name
                </label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="lastName"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="name@company.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  required
                />
              </div>

              <div className="relative">
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  required
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 top-7 pr-3 flex items-center text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 focus:outline-none"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <HiOutlineEyeOff className="text-center" />
                  ) : (
                    <HiOutlineEye className="text-center" />
                  )}
                </button>
              </div>

              <button
                type="submit"
                className="w-full text-gray-500 hover:text-gray-600 font-medium bg-primary-600 hover:bg-primary-700 border-2 border-gray-500 hover:border-gray-600 focus:outline-none rounded-lg text-sm px-5 py-2.5 text-center"
                disabled={emailloading || googleloading}
              >
                {emailloading ? (
                  <div className="flex justify-center items-center text-center text-mainColor space-x-3">
                    <TailSpin
                      visible={emailloading}
                      height="20"
                      width="20"
                      color="#00cafc"
                      ariaLabel="loading"
                    />{" "}
                    <span>Registering...</span>
                  </div>
                ) : (
                  "Register with Email"
                )}
              </button>
            </form>

            <div className="relative mt-6 text-center">
              <div className="absolute inset-0 flex justify-between flex-row items-center">
                <div className="w-1/3 border-t border-gray-400 dark:border-gray-600"></div>
                <div className="w-1/3 border-t border-gray-400 dark:border-gray-600"></div>
              </div>
              <div className="relative px-4 text-gray-500 dark:text-gray-300">
                or
              </div>
            </div>

            <button
              onClick={handleGoogleRegistration}
              disabled={emailloading || googleloading}
              className="w-full rounded-lg bg-mainColor text-white font-medium hover:bg-hovermainColor flex items-center justify-center focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed px-5 py-2.5 text-center"
            >
              {googleloading ? (
                <div className="flex justify-center items-center text-center text-white space-x-3">
                  <TailSpin
                    visible={googleloading}
                    height="20"
                    width="20"
                    color="#fff"
                    ariaLabel="loading"
                  />
                  <span>Registering with Google...</span>
                </div>
              ) : (
                <>
                  <FaGoogle className="h-5 w-5 mr-2" />
                  <p className="text-sm">Register with Google</p>
                </>
              )}
            </button>

            <p className="text-sm font-light text-gray-500 dark:text-gray-300">
              Already have an account?{" "}
              <Link
                to="/login"
                className="font-medium text-mainColor hover:underline"
              >
                Log in
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Registration;

import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Search, Check } from "lucide-react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";

const RequestsTable = ({ requestsData }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRequests, setFilteredRequests] = useState([]);

  useEffect(() => {
    const sortedRequests = [...requestsData].sort((a, b) => {
      if (a.status === "pending" && b.status !== "pending") {
        return -1;
      }
      if (a.status !== "pending" && b.status === "pending") {
        return 1;
      }
      return 0;
    });

    setFilteredRequests(sortedRequests);
  }, [requestsData]);

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = requestsData.filter((request) => {
      const firstName = request.firstName
        ? request.firstName.toLowerCase()
        : "";
      const lastName = request.lastName ? request.lastName.toLowerCase() : "";
      const phoneNumber = request.phoneNumber
        ? request.phoneNumber.toLowerCase()
        : "";

      return (
        firstName.includes(term) ||
        lastName.includes(term) ||
        phoneNumber.includes(term)
      );
    });

    setFilteredRequests(filtered);
  };

  const handleCompleteRequest = async (id) => {
    try {
      const requestRef = doc(db, "requests", id);
      await updateDoc(requestRef, {
        status: "completed",
        completedAt: new Date().toISOString(),
      });

      setFilteredRequests((prevRequests) =>
        prevRequests.map((request) =>
          request.id === id ? { ...request, status: "completed" } : request
        )
      );
    } catch (error) {
      console.error("Error updating request:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <motion.div
      className="bg-gray-800 bg-opacity-50 backdrop-blur-md shadow-lg rounded-xl p-6 border border-gray-700"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.4 }}
    >
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-gray-100">Request List</h2>
        <div className="relative">
          <input
            type="text"
            placeholder="Search requests..."
            className="bg-gray-700 text-white placeholder-gray-400 rounded-lg pl-10 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={handleSearch}
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" size={18} />
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-700">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Customer
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Phone
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Message
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>

          <tbody className="divide divide-gray-700">
            {filteredRequests.map((request) => (
              <motion.tr
                key={request.id}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                className="border-t border-gray-700"
              >
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-100">
                  {request.firstName} {request.lastName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-100">
                  {request.phoneNumber}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  <span
                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      request.status === "completed"
                        ? "bg-green-400 text-green-800"
                        : "bg-yellow-400 text-yellow-800"
                    }`}
                  >
                    {request.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {formatDate(request.createdAt)}
                </td>
                <td className="px-6 py-4 text-wrap text-sm text-gray-300">
                  <span>{request.message}</span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {request.status === "completed" ? (
                    <div className="text-xs text-wrap">
                      <span>No Action Available</span>
                    </div>
                  ) : (
                    <button
                      className="text-green-300 hover:text-green-200 mx-5"
                      onClick={() => handleCompleteRequest(request.id)}
                      disabled={request.status === "completed"}
                    >
                      <Check size={18} />
                    </button>
                  )}
                </td>
              </motion.tr>
            ))}
          </tbody>
        </table>
      </div>
    </motion.div>
  );
};

export default RequestsTable;

import React, { useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebase";

function SurroundingOutsideForm({ outside, onChange }) {
  const [loadingNormalImages, setLoadingNormalImages] = useState(false);
  const [loadingPanoramaImage, setLoadingPanoramaImage] = useState(false);

  const handleNormalImagesChange = async (e) => {
    const files = Array.from(e.target.files);
    const fileURLs = [];

    setLoadingNormalImages(true);

    for (const file of files) {
      const storageRef = ref(storage, `PostImages/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      fileURLs.push(downloadURL);
    }

    onChange({
      ...outside,
      normalImages: [...outside.normalImages, ...fileURLs],
    });

    setLoadingNormalImages(false);
  };

  const handlePanoramaImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setLoadingPanoramaImage(true);
      const storageRef = ref(storage, `PostImages/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      onChange({ ...outside, panoramaImage: downloadURL });
      setLoadingPanoramaImage(false);
    }
  };

  const removeNormalImage = async (index) => {
    const updatedImages = outside.normalImages.filter(
      (_, imgIndex) => imgIndex !== index
    );
    onChange({ ...outside, normalImages: updatedImages });
    // Remove from Firebase storage logic can be added here if needed
  };

  const removePanoramaImage = async () => {
    onChange({ ...outside, panoramaImage: "" });
    // Remove from Firebase storage logic can be added here if needed
  };

  return (
    <div className="mb-14">
      <label className="block text-lg font-medium text-gray-200 border-t pt-4">
        Normal Images
      </label>

      <label>
        <div
          className={`mt-1 flex items-center justify-center h-24 border border-gray-700 bg-gray-800 text-white rounded-md cursor-pointer ${
            loadingNormalImages ? "opacity-50" : ""
          }`}
        >
          <input
            type="file"
            multiple
            onChange={handleNormalImagesChange}
            className="hidden"
            disabled={loadingNormalImages}
          />
          <span className="text-gray-400">Click to upload Normal images</span>
        </div>
      </label>

      {loadingNormalImages && (
        <div className="mt-2 text-yellow-400">Uploading images...</div>
      )}
      {outside.normalImages.length > 0 && (
        <div className="mt-2">
          {outside.normalImages.map((image, imgIndex) => (
            <div key={imgIndex} className="flex items-center justify-between">
              <img
                src={image}
                alt={`Outside ${imgIndex}`}
                className="w-16 h-16 object-cover"
              />
              <button
                type="button"
                onClick={() => removeNormalImage(imgIndex)}
                className="ml-2 text-red-500"
              >
                Remove
              </button>
            </div>
          ))}
        </div>
      )}

      <label className="block text-lg font-medium text-gray-200 mt-16">
        Panorama Image
      </label>

      <label>
        <div
          className={`mt-1 flex items-center justify-center h-24 border border-gray-700 bg-gray-800 text-white rounded-md cursor-pointer ${
            loadingPanoramaImage ? "opacity-50" : ""
          }`}
        >
          <input
            type="file"
            onChange={handlePanoramaImageChange}
            className="hidden"
            disabled={loadingPanoramaImage}
          />
          <span className="text-gray-400">Click to upload panorama image</span>
        </div>
      </label>

      {loadingPanoramaImage && (
        <div className="mt-2 text-yellow-400">Uploading panorama...</div>
      )}
      {outside.panoramaImage && (
        <div className="mt-2 flex justify-between">
          <img
            src={outside.panoramaImage}
            alt="Panorama"
            className="w-fit h-52 object-cover"
          />
          <button
            type="button"
            onClick={removePanoramaImage}
            className="ml-2 text-red-500"
          >
            Remove
          </button>
        </div>
      )}
    </div>
  );
}

export default SurroundingOutsideForm;

import React, { useState } from "react";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { db, auth } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";
import Header from "../components/common/Header";
import { toast } from "react-hot-toast";

const AddAdminPage = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "employee",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const generatePassword = (firstName, lastName) => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const namePart = `${firstName.slice(0, 3)}${lastName.slice(0, 3)}`;
    const password = `${namePart}${hours}${minutes}${seconds}`;

    return password;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const password = generatePassword(formData.firstName, formData.lastName);
    const createdAt = new Date().toISOString();

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        password
      );

      const user = userCredential.user;
      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        role: formData.role,
        createdAt,
        lastLogin: new Date().toISOString(),
      });

      toast.success("Admin added successfully!");
      await sendPasswordResetEmail(auth, formData.email);
      toast.success("A password reset email has been sent.");
    } catch (error) {
      toast.error(`Failed to add admin: ${error.code}`);
    }

    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      role: "employee",
    });
  };

  return (
    <div className="flex-1 overflow-auto relative z-10">
      <Header title={"Add an admin"} />

      <main className="max-w-7xl mx-auto py-6 px-4 lg:px-8">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div className="flex gap-4">
            <div className="flex-1">
              <label htmlFor="firstName" className="block text-white">
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
                className="mt-1 block w-full rounded-md bg-gray-700 text-white p-2"
              />
            </div>
            <div className="flex-1">
              <label htmlFor="lastName" className="block text-white">
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
                className="mt-1 block w-full rounded-md bg-gray-700 text-white p-2"
              />
            </div>
          </div>

          <div>
            <label htmlFor="email" className="block text-white">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="mt-1 block w-full rounded-md bg-gray-700 text-white p-2"
            />
          </div>

          <div>
            <label htmlFor="role" className="block text-white">
              Role
            </label>
            <select
              id="role"
              name="role"
              value={formData.role}
              onChange={handleChange}
              required
              className="mt-1 block w-full rounded-md bg-gray-700 text-white p-2"
            >
              <option value="employee">Employee</option>
              <option value="manager">Manager</option>
            </select>
          </div>

          <button
            type="submit"
            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-500"
          >
            Add Admin
          </button>
        </form>
      </main>
    </div>
  );
};

export default AddAdminPage;

import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const DefaultIcon = L.icon({
  iconUrl: require("../../../assets/pins/main.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
L.Marker.prototype.options.icon = DefaultIcon;

const LocationSelector = ({ location, onChange }) => {
  const [markerPosition, setMarkerPosition] = useState([
    location.coordinates.lat || 9.03,
    location.coordinates.long || 38.74,
  ]);

  const handleMapClick = (e) => {
    const { lat, lng } = e.latlng;
    setMarkerPosition([lat, lng]);
    onChange({
      ...location,
      coordinates: { lat, long: lng },
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onChange({
      ...location,
      [name]: value,
    });
  };

  return (
    <div className="border border-gray-500 rounded-lg">
      <MapContainer
        center={markerPosition}
        zoom={14}
        style={{ height: "400px", width: "100%" }}
        className="mb-4"
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={markerPosition} />
        <MapEventsWrapper handleMapClick={handleMapClick} />
      </MapContainer>

      <div className="p-4">
        <h3 className="text-lg font-medium text-gray-200">
          Selected Location:
        </h3>
        <p className="text-gray-400">Latitude: {markerPosition[0]}</p>
        <p className="text-gray-400">Longitude: {markerPosition[1]}</p>

        <div className="mt-4">
          <label
            htmlFor="locationName"
            className="block text-sm font-medium text-gray-200"
          >
            Location Name
          </label>
          <input
            type="text"
            name="name"
            id="locationName"
            value={location.name}
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 border border-gray-700 bg-gray-800 text-white rounded-md"
            placeholder="Enter location name"
          />
        </div>

        <div className="mt-4">
          <label
            htmlFor="city"
            className="block text-sm font-medium text-gray-200"
          >
            City
          </label>
          <input
            type="text"
            name="city"
            id="city"
            value={location.city}
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 border border-gray-700 bg-gray-800 text-white rounded-md"
            placeholder="Enter city"
          />
        </div>
      </div>
    </div>
  );
};

const MapEventsWrapper = ({ handleMapClick }) => {
  useMapEvents({
    click: handleMapClick,
  });

  return null; // This component is just for organizing the map event handling
};

export default LocationSelector;

import React, { useState, useEffect, useRef, useContext } from "react";
import { X, SendHorizontal, MessageCircleMore } from "lucide-react";
import { TailSpin } from "react-loader-spinner";
import InputEmoji from "react-input-emoji";
import { motion, AnimatePresence } from "framer-motion";
import { useAuth } from "../../../context/AuthContext";
import { db } from "../../../firebase";
import {
  doc,
  setDoc,
  addDoc,
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  updateDoc,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import Moment from "react-moment";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import NotificationSound from "../../../assets/sounds/notification.mp3";
import Linkify from "linkify-react";
import { ThemeContext } from "../../../context/ThemeContext";

function ContactButton() {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userName, setUserName] = useState(Cookies.get("userName") || "");
  const [userId, setUserId] = useState(Cookies.get("uid") || null);
  const [isAskingName, setIsAskingName] = useState(false);
  const [conversation, setConversation] = useState(null);
  const [conversationId, setConversationId] = useState(null);
  const messagesEndRef = useRef(null);
  const { currentUser, firstName, lastName } = useAuth();
  const { theme } = useContext(ThemeContext);

  const isDarkMode = theme === "dark";

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const toggleChat = async () => {
    setIsOpen(!isOpen);
    scrollToBottom();

    if (!isOpen && conversation?.adminLastMessageStatus === "unread") {
      try {
        const conversationRef = doc(db, "contacts", conversationId);
        await setDoc(
          conversationRef,
          { adminLastMessageStatus: "read" },
          { merge: true }
        );
        setConversation((prev) => ({
          ...prev,
          adminLastMessageStatus: "read",
        }));
      } catch (error) {
        console.error("Error updating message status to read:", error);
      }
    }
  };

  const fetchConversationId = (uid) => {
    try {
      const conversationsRef = collection(db, "contacts");
      const q = query(conversationsRef, where("uid", "==", uid));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          setConversationId(doc.id);
          setConversation(doc.data());
        }
      });

      return unsubscribe;
    } catch (error) {
      console.error("Error fetching conversation:", error);
    }
  };

  useEffect(() => {
    if (currentUser || userName) {
      const uid = currentUser ? currentUser.uid : userId;
      const unsubscribe = fetchConversationId(uid);
      setUserId(uid);
      return () => unsubscribe;
    }
  }, [userName, currentUser]);

  useEffect(() => {
    if (conversationId && isOpen) {
      const messagesRef = collection(
        db,
        "contacts",
        conversationId,
        "messages"
      );
      const q = query(messagesRef, orderBy("createdAt", "asc"));

      const unsubscribe = onSnapshot(q, async (snapshot) => {
        const messagesData = snapshot.docs.map((doc) => {
          const data = doc.data();

          const createdAt =
            data.createdAt instanceof Timestamp
              ? data.createdAt.toDate().toISOString()
              : data.createdAt;

          return {
            id: doc.id,
            ...data,
            createdAt,
          };
        });

        setMessages(messagesData);
        scrollToBottom();

        if (conversation?.adminLastMessageStatus === "unread") {
          try {
            const conversationRef = doc(db, "contacts", conversationId);
            await updateDoc(conversationRef, {
              adminLastMessageStatus: "read",
            });

            setConversation((prev) => ({
              ...prev,
              adminLastMessageStatus: "read",
            }));
          } catch (error) {
            console.error("Error updating message status to read:", error);
          }
        }
        scrollToBottom();
      });

      return () => unsubscribe();
    }
  }, [conversationId, conversation, isOpen]);

  useEffect(() => {
    if (conversation?.adminLastMessageStatus === "unread") {
      const audio = new Audio(NotificationSound);
      audio.play().catch((error) => {
        console.error("Error playing sound:", error);
      });

      toast.custom((t) => (
        <div
          className={`${
            t.visible ? "animate-enter" : "animate-leave"
          } max-w-md w-full bg-white shadow-lg rounded-xl pointer-events-auto flex ring-1 ring-black ring-opacity-5 border border-gray-300 transition-transform transform duration-150`}
        >
          <div className="flex-1 w-0 p-4">
            <div className="flex items-start">
              <div className="ml-3 flex-1">
                <p className="text-sm font-medium text-mainColor">
                  New Message
                </p>
                <p className="mt-1 text-sm text-gray-600">
                  You have a new message from the admin!
                </p>
              </div>
            </div>
          </div>
          <div className="flex">
            <button
              onClick={() => toast.dismiss(t.id)}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-mainColor hover:bg-gray-200 transition-colors duration-150 focus:outline-none"
            >
              <X />
            </button>
          </div>
        </div>
      ));
    }
  }, [conversation]);

  const handleSendMessage = async () => {
    if (message.trim() === "") return;
    setLoading(true);

    try {
      const uid = currentUser ? currentUser.uid : userId;

      if (!conversationId) {
        const newContactRef = doc(collection(db, "contacts"));
        const contactData = {
          name: currentUser ? `${firstName} ${lastName}` : userName,
          uid: uid,
          customerLastMessageStatus: "unread",
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        };

        await setDoc(newContactRef, contactData);
        setConversationId(newContactRef.id);

        await addDoc(collection(db, "contacts", newContactRef.id, "messages"), {
          text: message,
          senderId: uid,
          createdAt: serverTimestamp(),
        });

        if (!currentUser) {
          Cookies.set("uid", uid, {
            expires: 7,
            sameSite: "None",
            secure: true,
          });
          Cookies.set("userName", userName, {
            expires: 7,
            sameSite: "None",
            secure: true,
          });
        }
      } else {
        await addDoc(collection(db, "contacts", conversationId, "messages"), {
          text: message,
          senderId: uid,
          createdAt: serverTimestamp(),
        });

        const conversationRef = doc(db, "contacts", conversationId);
        await updateDoc(conversationRef, {
          customerLastMessageStatus: "unread",
          updatedAt: serverTimestamp(),
        });
      }

      setMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setLoading(false);
      scrollToBottom();
    }
  };

  const handleAskName = (e) => {
    e.preventDefault();
    if (userName.trim() === "") {
      return;
    }

    if (!currentUser) {
      setUserId(`${userName}-${Date.now()}`);
    }
    setIsAskingName(false);
  };

  const handleOpenChat = () => {
    if (!currentUser && !userName) {
      setIsAskingName(true);
    }
    toggleChat();
  };

  return (
    <div className="fixed lg:bottom-4 lg:right-7 bottom-28 right-1 z-50">
      <div className="relative">
        <button
          onClick={handleOpenChat}
          className={`bg-mainColor text-white p-4 rounded-full shadow-lg hover:bg-hovermainColor transition-transform transform duration-500 ${
            isOpen ? "rotate-180" : "rotate-0"
          } ${
            conversation?.adminLastMessageStatus === "unread" && "animate-blob"
          }`}
        >
          {isOpen ? (
            <X className="rotate-180" />
          ) : (
            <MessageCircleMore className="" />
          )}
        </button>
        {conversation?.adminLastMessageStatus === "unread" && (
          <div className="absolute p-2 top-0 right-0 bg-red-500 rounded-full" />
        )}
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, scale: 0, originX: 1, originY: 1 }}
            animate={{ opacity: 1, scale: 1, originX: 1, originY: 1 }}
            exit={{ opacity: 0, scale: 0, originX: 1, originY: 1 }}
            transition={{ duration: 0.4, ease: "easeOut" }}
            className="fixed lg:bottom-20 bottom-0 right-0 md:right-5 w-full md:w-96 bg-white dark:bg-gray-800 transition-colors duration-300 shadow-xl rounded-xl flex flex-col overflow-hidden max-h-[30rem] min-h-40 border border-gray-300 dark:border-gray-700 z-50"
          >
            <div className="flex justify-between bg-gray-100 dark:bg-gray-900 items-center text-black dark:text-white p-4">
              <h2 className="text-base">Contact Chat</h2>
              <button onClick={toggleChat}>
                <X size={24} />
              </button>
            </div>

            <div className="flex-1 px-5 pt-5 overflow-y-scroll no-scrollbar">
              {isAskingName && (
                <div className="mb-4">
                  <p className="text-gray-700 dark:text-gray-300 mb-2">
                    What should we call you?
                  </p>
                  <form onSubmit={handleAskName}>
                    <input
                      type="text"
                      className="border p-2 pl-4 rounded-xl w-full dark:bg-backgroundDark text-black dark:text-white focus:outline-none"
                      placeholder="Enter your name..."
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                    <button
                      type="submit"
                      className="bg-mainColor text-white p-2 mt-2 w-full rounded disabled:bg-gray-600"
                      disabled={!userName.trim()}
                    >
                      Submit
                    </button>
                  </form>
                </div>
              )}

              {!isAskingName && messages.length === 0 && (
                <p className="text-gray-700 dark:text-gray-300 mb-2 py-4 flex text-center justify-center bg-gray-300 dark:bg-gray-600 rounded-lg">
                  Hello{" "}
                  {currentUser ? `${firstName} ${lastName}` : userName || ""}!
                  How can we help you?
                </p>
              )}

              {!isAskingName &&
                messages.map((msg, index) => {
                  const isOwnMessage =
                    msg.senderId === (currentUser?.uid || userId);

                  return (
                    <div
                      key={index}
                      className={`flex flex-col mb-3 ${
                        isOwnMessage ? "items-end" : "items-start"
                      }`}
                    >
                      <div className="relative flex flex-row">
                        {!isOwnMessage ? (
                          <>
                            <div className="absolute w-5 h-5 -left-[0.4rem] bottom-0 bg-gray-500 rounded-br-2xl"></div>
                            <div className="absolute w-5 h-5 -left-[1.25rem] bottom-0 bg-backgroundColor dark:bg-backgroundDark rounded-br-xl transition-colors duration-300"></div>
                          </>
                        ) : (
                          <>
                            <div className="absolute w-5 h-5 -right-[0.4rem] bottom-0 bg-mainColor rounded-bl-2xl"></div>
                            <div className="absolute w-5 h-5 -right-[1.25rem] bottom-0 bg-backgroundColor dark:bg-backgroundDark rounded-bl-xl transition-colors duration-300"></div>
                          </>
                        )}

                        <div
                          className={`max-w-xs lg:max-w-md py-2 px-4 ${
                            isOwnMessage
                              ? "bg-mainColor text-white rounded-2xl"
                              : "bg-gray-500 dark:bg-gray-500 text-white rounded-2xl"
                          }`}
                        >
                          <Linkify
                            options={{
                              attributes: {
                                style: {
                                  color: "blue",
                                  textDecoration: "underline",
                                },
                                target: "_blank",
                              },
                            }}
                          >
                            {msg.text}
                          </Linkify>
                        </div>
                      </div>

                      <div
                        className={`flex text-xs text-gray-400 font-medium mt-1 ${
                          isOwnMessage ? "justify-end" : "justify-start"
                        }`}
                      >
                        <Moment fromNow>{msg.createdAt}</Moment>
                      </div>
                    </div>
                  );
                })}
              <div ref={messagesEndRef} />
            </div>

            {!isAskingName && (
              <div className="p-3">
                <div className="flex items-center relative">
                  <div
                    className={`transition-all duration-150 ${
                      message.trim() || loading ? "mr-10" : "mr-0"
                    } w-full`}
                  >
                    <InputEmoji
                      value={message}
                      onChange={setMessage}
                      onEnter={handleSendMessage}
                      cleanOnEnter
                      placeholder="Type your message..."
                      background={isDarkMode ? "#1f2937" : "#ffffff"}
                      color={isDarkMode ? "#ffffff" : "#000000"}
                      theme={isDarkMode ? "dark" : "light"}
                      borderColor="#00a3cc"
                    />
                  </div>

                  {loading ? (
                    <div className="absolute right-3 flex items-center">
                      <TailSpin
                        color="#00a3cc"
                        height={20}
                        width={20}
                        strokeWidth={4}
                      />
                    </div>
                  ) : (
                    <button
                      onClick={handleSendMessage}
                      disabled={!message.trim()}
                      className="absolute right-2 p-1 bg-transparent text-mainColor hover:text-hovermainColor disabled:opacity-0 transition-opacity duration-150 flex items-center"
                    >
                      <SendHorizontal size={26} />
                    </button>
                  )}
                </div>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default ContactButton;

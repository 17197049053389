import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

function PostCard({ post, id }) {
  const { name, purchaseType, outside, surrounding, rooms, location } = post;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const allImages = [
    ...outside.normalImages,
    ...rooms.flatMap((room) =>
      room.instances.flatMap((instance) => instance.normalImages)
    ),
    ...surrounding.normalImages,
  ];

  const handleNextImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    setCurrentImageIndex((prevIndex) =>
      prevIndex === allImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? allImages.length - 1 : prevIndex - 1
    );
  };

  const getName = () => {
    const Names = name.split("|");

    const languageMap = {
      en: Names[0],
      am: Names[1],
      ar: Names[2],
    };

    return languageMap[language] || Names[0];
  };

  return (
    <Link
      to={`/post/${post.id || id}`}
      className="w-full max-w-md mx-auto overflow-hidden"
    >
      <div className="relative">
        {loading && (
          <div className="absolute inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50 dark:bg-black dark:bg-opacity-50 rounded-tl-[10px] rounded-br-[10px]">
            <TailSpin color="#00a3cc" height={30} width={30} />
          </div>
        )}
        <img
          src={allImages[currentImageIndex]}
          alt="Property"
          className={`w-full h-64 object-cover shadow-lg rounded-tl-[10px] rounded-br-[10px] ${
            loading ? "invisible" : "visible"
          }`}
          onLoad={() => setLoading(false)}
        />
        <button
          onClick={handlePrevImage}
          className="absolute text-sm left-1 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-30 hover:bg-opacity-50 transition-colors duration-200 text-white p-1 rounded-full"
        >
          <FaChevronLeft />
        </button>
        <button
          onClick={handleNextImage}
          className="absolute text-sm right-1 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-30 hover:bg-opacity-50 transition-colors duration-200 text-white p-1 rounded-full"
        >
          <FaChevronRight />
        </button>
        <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 dark:bg-opacity-70 rounded-tr-lg text-white py-1 px-2">
          <h3 className="text-base font-medium">{getName()}</h3>
        </div>
        <div className="absolute top-0 right-0 bg-black bg-opacity-30 dark:bg-opacity-70 text-white py-1 px-2">
          <h3 className="text-base font-medium">
            {purchaseType === "Rent" ? t("for_rent") : t("for_sale")}
          </h3>
        </div>
      </div>

      <div className="p-1 flex justify-between text-sm">
        <p className="text-gray-700 dark:text-gray-200 font-light">
          {location.name}, {location.city}
        </p>
      </div>
    </Link>
  );
}

export default PostCard;

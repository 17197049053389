import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Sidebar from "./components/common/Sidebar";
import OverviewPage from "./pages/OverviewPage";
import PostsPage from "./pages/PostsPage";
import UsersPage from "./pages/UsersPage";
import SalesPage from "./pages/SalesPage";
import AddAdminPage from "./pages/AddAdminPage";
import { useAuth } from "../context/AuthContext";
import CreatePostPage from "./pages/CreatePostPage";
import RequestsPage from "./pages/RequestsPage";
import MessagesPage from "./pages/MessagesPage";
import ChatSpace from "./components/messages/ChatSpace";
import ContactsPage from "./pages/ContactsPage";
import ContactsChatSpace from "./components/contacts/ContactsChatSpace";
import { useEffect, useState } from "react";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../firebase";
import NotificationSound from "../assets/sounds/notification.mp3";
import toast from "react-hot-toast";
import { X } from "lucide-react";
import EmployeeOverview from "./pages/EmployeeOverview";

function Admin() {
  const { currentUser, role, firstName, lastName } = useAuth();
  const [conversations, setConversations] = useState([]);
  const [unreadCounts, setUnreadCounts] = useState({
    conversations: 0,
    contacts: 0,
    requests: 0,
  });

  const navigate = useNavigate();

  const isAuthorized =
    !!currentUser && (role === "employee" || role === "manager");

  useEffect(() => {
    const q1 = query(
      collection(db, "conversations"),
      where("customerLastMessageStatus", "==", "unread")
    );

    const q2 = query(
      collection(db, "contacts"),
      where("customerLastMessageStatus", "==", "unread")
    );

    const q3 = query(
      collection(db, "requests"),
      where("status", "==", "pending")
    );

    const unsubscribe1 = onSnapshot(q1, (snapshot) => {
      const convos = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        source: "conversations",
      }));
      setConversations((prevConvos) => [...prevConvos, ...convos]);

      setUnreadCounts((prevCounts) => ({
        ...prevCounts,
        conversations: convos.length,
      }));
    });

    const unsubscribe2 = onSnapshot(q2, (snapshot) => {
      const contacts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        source: "contacts",
      }));
      setConversations((prevConvos) => [...prevConvos, ...contacts]);

      setUnreadCounts((prevCounts) => ({
        ...prevCounts,
        contacts: contacts.length,
      }));
    });

    const unsubscribe3 = onSnapshot(q3, (snapshot) => {
      const pendingConvos = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        source: "requests",
      }));
      setConversations((prevConvos) => [...prevConvos, ...pendingConvos]);

      setUnreadCounts((prevCounts) => ({
        ...prevCounts,
        requests: pendingConvos.length,
      }));
    });

    return () => {
      unsubscribe1();
      unsubscribe2();
      unsubscribe3();
    };
  }, []);

  useEffect(() => {
    const handleTakeToPage = (source) => {
      if (source === "conversations") {
        navigate("/admin/messages");
      } else if (source === "contacts") {
        navigate("/admin/contact");
      } else if (source === "requests") {
        navigate("/admin/requests");
      } else {
        toast.error(
          "Unexpected error occord, Unknown source:",
          source,
          "please refresh the page!!"
        );
      }
    };

    if (conversations.length > 0) {
      const audio = new Audio(NotificationSound);
      audio.play().catch((error) => {
        console.error("Error playing sound:", error);
      });

      conversations.forEach((conversation) => {
        const toastId = toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-xl pointer-events-auto flex ring-1 ring-black ring-opacity-5 border border-gray-300 transition-transform transform duration-150 cursor-pointer`}
            onClick={() => {
              handleTakeToPage(conversation.source);
              toast.dismiss(t.id);
            }}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                {conversation.source === "conversations" && (
                  <div className="flex-shrink-0 pt-0.5">
                    <img
                      className="h-14 w-14 rounded-full"
                      src={conversation.postImg}
                      alt="post"
                    />
                  </div>
                )}
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-mainColor">
                    New{" "}
                    {conversation.source === "conversations"
                      ? "Message"
                      : conversation.source === "requests"
                      ? "Request"
                      : conversation.source === "contacts"
                      ? "Contact Message"
                      : "Notification"}
                  </p>
                  {conversation.source === "conversations" && (
                    <p className="mt-1 text-xs text-gray-600">
                      {conversation.postName}
                    </p>
                  )}
                  <p className="mt-1 text-sm text-gray-600">
                    There is a new{" "}
                    {conversation.source === "conversations"
                      ? "message"
                      : conversation.source === "requests"
                      ? "request"
                      : conversation.source === "contacts"
                      ? "contact message"
                      : "notification"}
                    !
                  </p>
                </div>
              </div>
            </div>
            <div className="flex">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  toast.dismiss(t.id);
                }}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-mainColor hover:bg-gray-200 transition-colors duration-150 focus:outline-none"
              >
                <X />
              </button>
            </div>
          </div>
        ));

        setTimeout(() => {
          toast.dismiss(toastId);
        }, 2000);
      });
    }
  }, [conversations, navigate]);

  return (
    <div className="flex h-screen bg-gray-900 text-gray-100 overflow-hidden">
      <div className="fixed inset-0 z-0">
        <div className="absolute inset-0 bg-gray-800 opacity-80" />
        <div className="absolute inset-0 backdrop-blur-sm" />
      </div>

      {isAuthorized && <Sidebar role={role} unreadCounts={unreadCounts} />}

      <Routes>
        <Route
          path="/"
          element={
            isAuthorized && role === "manager" ? (
              <OverviewPage />
            ) : isAuthorized && role === "employee" ? (
              <EmployeeOverview
                firstName={firstName}
                unreadCounts={unreadCounts}
              />
            ) : (
              <Navigate to="/error-page" replace />
            )
          }
        />
        <Route
          path="posts"
          element={
            isAuthorized && role === "manager" ? (
              <PostsPage />
            ) : (
              <Navigate to="/error-page" replace />
            )
          }
        />
        <Route
          path="create-post"
          element={
            isAuthorized &&
            role === "manager" && (
              <CreatePostPage
                currentUser={currentUser}
                firstName={firstName}
                lastName={lastName}
              />
            )
          }
        />
        <Route
          path="users"
          element={
            isAuthorized && role === "manager" ? (
              <UsersPage role={role} />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="sales"
          element={
            isAuthorized ? <SalesPage /> : <Navigate to="/error-page" replace />
          }
        />
        <Route
          path="requests"
          element={
            isAuthorized ? (
              <RequestsPage />
            ) : (
              <Navigate to="/error-page" replace />
            )
          }
        />
        <Route
          path="messages"
          element={
            isAuthorized ? (
              <MessagesPage />
            ) : (
              <Navigate to="/error-page" replace />
            )
          }
        />
        <Route
          path="contact"
          element={
            isAuthorized ? (
              <ContactsPage />
            ) : (
              <Navigate to="/error-page" replace />
            )
          }
        />
        <Route
          path="messages/:chatId"
          element={
            isAuthorized ? (
              <ChatSpace
                currentUser={currentUser}
                firstName={firstName}
                lastName={lastName}
              />
            ) : (
              <Navigate to="/error-page" replace />
            )
          }
        />
        <Route
          path="contact/:uid"
          element={
            isAuthorized ? (
              <ContactsChatSpace
                currentUser={currentUser}
                firstName={firstName}
                lastName={lastName}
              />
            ) : (
              <Navigate to="/error-page" replace />
            )
          }
        />
        <Route
          path="add-admin"
          element={
            isAuthorized && role === "manager" ? (
              <AddAdminPage />
            ) : (
              <Navigate to="/error-page" replace />
            )
          }
        />
        <Route
          path="/*"
          element={!isAuthorized && <Navigate to="/error-page" replace />}
        />
      </Routes>
    </div>
  );
}

export default Admin;

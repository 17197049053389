import React, { useContext, useState } from "react";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../../context/AuthContext";
import { SendHorizontal } from "lucide-react";
import { TailSpin } from "react-loader-spinner";
import InputEmoji from "react-input-emoji";
import { ThemeContext } from "../../context/ThemeContext";

function MessageInput({ conversationId, scrollToBottom }) {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const { theme } = useContext(ThemeContext);

  const isDarkMode = theme === "dark";

  const sendMessage = async (e) => {
    let messageText;

    if (typeof e === "string") {
      messageText = e;
    } else {
      e.preventDefault();
      messageText = message;
    }

    if (message.trim() === "") return;
    setLoading(true);

    try {
      if (conversationId) {
        await addDoc(
          collection(db, "conversations", conversationId, "messages"),
          {
            message,
            senderId: currentUser.uid,
            createdAt: serverTimestamp(),
          }
        );

        const conversationRef = doc(db, "conversations", conversationId);
        await updateDoc(conversationRef, {
          customerLastMessageStatus: "unread",
          updatedAt: serverTimestamp(),
        });
        setLoading(false);
        scrollToBottom();
        setMessage("");
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleMessageChange = (msg) => {
    setMessage(msg);
  };

  return (
    <form
      className="flex items-center p-3 border-gray-200 bg-backgroundColor dark:bg-backgroundDark"
      onSubmit={sendMessage}
    >
      <div className="flex items-center relative w-full">
        <div
          className={`transition-all duration-150 ${
            message || loading ? "mr-10" : "mr-0"
          } w-full`}
        >
          <InputEmoji
            value={message}
            onChange={handleMessageChange}
            cleanOnEnter
            onEnter={sendMessage}
            placeholder="Type your message..."
            background={isDarkMode ? "#1f2937" : "#ffffff"}
            color={isDarkMode ? "#ffffff" : "#000000"}
            theme={isDarkMode ? "dark" : "light"}
            borderColor="#00a3cc"
          />
        </div>
        {loading ? (
          <div className="absolute right-2 flex items-center">
            <TailSpin color="#00a3cc" height={20} width={20} strokeWidth={4} />
          </div>
        ) : (
          <button
            type="submit"
            disabled={!message}
            className="absolute right-2 p-1 bg-transparent text-mainColor hover:text-hovermainColor disabled:opacity-0 transition-opacity duration-150 flex items-center"
          >
            <SendHorizontal size={26} />
          </button>
        )}
      </div>
    </form>
  );
}

export default MessageInput;

import React, { useContext, useEffect, useState } from "react";
import { Modal, message } from "antd";
import { db } from "../../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
  Timestamp,
} from "firebase/firestore";
import { TailSpin } from "react-loader-spinner";
import { ThemeContext } from "../../../context/ThemeContext";

const PostManage = ({ currentUser }) => {
  const [requestData, setrequestData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { theme } = useContext(ThemeContext);

  const isDarkMode = theme === "dark";

  useEffect(() => {
    if (!currentUser) return;

    const fetchRequests = async () => {
      setLoading(true);
      try {
        const requestsCollection = collection(db, "requests");
        const q = query(
          requestsCollection,
          where("uid", "==", currentUser.uid)
        );
        const requestsSnapshot = await getDocs(q);

        const requestsList = requestsSnapshot.docs.map((doc) => {
          const data = doc.data();

          const createdAt =
            data.createdAt instanceof Timestamp
              ? data.createdAt.toDate().toISOString()
              : data.createdAt;

          return {
            id: doc.id,
            ...data,
            createdAt,
          };
        });

        setrequestData(requestsList);
      } catch (error) {
        message.error("Error fetching requests: " + error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchRequests();
  }, [currentUser]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  const handleDelete = (requestId, status) => {
    Modal.confirm({
      title: (
        <>
          <p className="text-black dark:text-white">
            {`Are you sure you want to ${
              status === "completed" ? "delete" : "cancel"
            } this request?`}
          </p>
        </>
      ),
      content: (
        <>
          <p className="text-black dark:text-white">
            This action cannot be undone.
          </p>
        </>
      ),
      okText: `${status === "completed" ? "Delete" : "Cancel Request"}`,
      cancelText: "Close",
      okType: "danger",
      centered: true,
      styles: {
        content: {
          backgroundColor: isDarkMode ? "#1f2937" : "#ffffff",
        },
      },
      okButtonProps: {
        style: {
          backgroundColor: status === "completed" ? "#ef4444" : "#fb923c",
          color: "#ffffff",
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: isDarkMode ? "#1f2937" : "#ffffff",
          color: isDarkMode ? "white" : "black",
        },
      },
      onOk: async () => {
        try {
          const requestRef = doc(db, "requests", requestId);
          await deleteDoc(requestRef);
          message.success(
            `Request ${
              status === "completed" ? "deleted" : "canceled"
            } successfully`
          );

          const updatedrequestData = requestData.filter(
            (request) => request.id !== requestId
          );
          setrequestData(updatedrequestData);
        } catch (error) {
          message.error("Error deleting request: " + error.message);
        }
      },
    });
  };

  return (
    <div className="text-gray-800 dark:text-gray-200">
      <div className="flex p-6 flex-col justify-center bg-gray-200 dark:bg-gray-900 rounded-lg">
        <div className="flex justify-between border-b-2 border-gray-300 dark:border-gray-600 pb-2 mb-4 items-center">
          <h2 className="text-xl font-semibold">Requests</h2>
        </div>

        {loading ? (
          <div className="flex justify-center items-center text-center text-mainColor dark:text-gray-200 space-x-3 my-6">
            <TailSpin
              visible={loading}
              height="40"
              width="40"
              color="#00cafc"
              ariaLabel="loading"
            />
          </div>
        ) : (
          <div>
            {requestData.length > 0 ? (
              <>
                <h3 className="flex justify-center text-center my-4 font-semibold">
                  Your Requests
                </h3>
                <ul>
                  {requestData.map((request) => (
                    <li key={request.id} className="mb-4">
                      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700">
                        <div className="flex justify-between mb-4">
                          <div>
                            <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                              {request.firstName} {request.lastName}
                            </h3>
                            <p className="text-base text-gray-500 dark:text-gray-400">
                              {request.locationName}, {request.city}
                            </p>
                            <p className="text-base mt-2 text-gray-700 dark:text-gray-300">
                              <strong>Phone Number:</strong>{" "}
                              {request.phoneNumber}
                            </p>
                          </div>
                          <div
                            className={`px-3 h-5 text-sm font-semibold rounded-alg flex justify-center text-center select-none
            ${
              request.status === "completed"
                ? "bg-green-200 text-green-700"
                : "bg-yellow-200 text-yellow-700"
            }`}
                          >
                            {request.status}
                          </div>
                        </div>
                        <p className="text-gray-700 dark:text-gray-300 mb-3">
                          <strong>Message:</strong> {request.message}
                        </p>
                        <div className="flex justify-between items-center">
                          <div className="text-xs text-gray-500 dark:text-gray-400">
                            <strong>Requested at:</strong>{" "}
                            {formatDate(request.createdAt)}
                          </div>
                          <button
                            onClick={() =>
                              handleDelete(request.id, request.status)
                            }
                            className={`px-4 py-2 text-sm font-medium text-white rounded-lg transition duration-200 ${
                              request.status === "completed"
                                ? "bg-red-500 hover:bg-red-600"
                                : "bg-orange-400 hover:bg-orange-500"
                            }`}
                          >
                            {request.status === "completed"
                              ? "Delete"
                              : "Cancel Request"}
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <>
                <h3 className="flex justify-center text-center my-6 select-none">
                  You haven't made any request.
                </h3>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PostManage;

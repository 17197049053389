import React, { useState } from "react";
import { auth } from "../../../firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { TailSpin } from "react-loader-spinner";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ type: "", text: "" });

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage({
        type: "success",
        text: "Password reset email sent. Please check your inbox.",
      });
    } catch (error) {
      console.error(error);
      setMessage({ type: "error", text: error.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="font-Quicksand bg-backgroundColor dark:bg-gray-800">
      <div className="flex flex-col items-center justify-center px-6 py-28 mx-auto h-full lg:py-32">
        <div className="w-full bg-transparent border border-gray-400 dark:border-gray-600 rounded-alg max-w-lg xl:p-0">
          <div className="p-6 lg:m-5 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">
              Reset your password
            </h1>

            {message.text && (
              <div
                className={`mb-4 p-3 rounded ${
                  message.type === "success"
                    ? "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-100"
                    : "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-100"
                }`}
              >
                {message.text}
              </div>
            )}

            <form
              className="space-y-4 md:space-y-6"
              onSubmit={handlePasswordReset}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="name@company.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  required
                />
              </div>

              <button
                type="submit"
                className="w-full text-gray-500 hover:text-gray-600 font-medium bg-primary-600 hover:bg-primary-700 border-2 border-gray-500 hover:border-gray-600 focus:outline-none rounded-lg text-sm px-5 py-2.5 text-center dark:text-gray-300 dark:bg-primary-700"
                disabled={loading}
              >
                {loading ? (
                  <div className="flex justify-center items-center text-center text-mainColor space-x-3">
                    <TailSpin
                      visible={loading}
                      height="20"
                      width="20"
                      color="#00cafc"
                      ariaLabel="loading"
                    />{" "}
                    <span>Sending...</span>
                  </div>
                ) : (
                  "Send Password Reset Email"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ForgotPassword;

import React from "react";
import { TailSpin } from "react-loader-spinner";

function Loader() {
  return (
    <div className="h-screen pt-20 flex justify-center items-center text-center text-xl font-bold w-full bg-backgroundColor dark:bg-backgroundDark space-x-5">
      <TailSpin
        visible={true}
        height="60"
        width="60"
        color="#00a3cc"
        ariaLabel="tail-spin-loading"
      />
      <span className="text-black dark:text-white">Loading...</span>
    </div>
  );
}

export default Loader;

import React, { useState, useEffect } from "react";
import ChatSidebar from "../components/messages/ChatSidebar";
import ChatArea from "../components/messages/ChatArea";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  Timestamp,
} from "firebase/firestore";
import { useAuth } from "../context/AuthContext";
import { useParams } from "react-router-dom";
import ChatRightSidebar from "../components/messages/ChatRightSidebar";
import useWindowDimensions from "../hooks/useWindowDimensions";

function Messages() {
  const { currentUser } = useAuth();
  const { chatId } = useParams();
  const [conversations, setConversations] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState(chatId);
  const [loading, setLoading] = useState(true);
  const { width } = useWindowDimensions();
  const isSmallScreen = width < 768;

  useEffect(() => {
    const q = query(
      collection(db, "conversations"),
      where("customerId", "==", currentUser.uid)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const convos = snapshot.docs.map((doc) => {
        const data = doc.data();

        const createdAt =
          data.createdAt instanceof Timestamp
            ? data.createdAt.toDate().toISOString()
            : data.createdAt;

        const updatedAt =
          data.updatedAt instanceof Timestamp
            ? data.updatedAt.toDate().toISOString()
            : data.updatedAt;

        return {
          id: doc.id,
          ...data,
          createdAt,
          updatedAt,
        };
      });

      setConversations(convos);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [currentUser]);

  return (
    <div className="flex w-full h-screen">
      {(!selectedChatId || !isSmallScreen) && (
        <ChatSidebar
          loading={loading}
          conversations={conversations}
          selectedChatId={selectedChatId}
          setSelectedChatId={setSelectedChatId}
        />
      )}
      {selectedChatId ? (
        <>
          <ChatArea
            setSelectedChatId={setSelectedChatId}
            chatId={selectedChatId}
          />
          {!isSmallScreen && <ChatRightSidebar chatId={selectedChatId} />}
        </>
      ) : (
        !isSmallScreen && (
          <div className="hidden lg:flex flex-1 text-center text-gray-500 items-center justify-center">
            Select a chat to view messages
          </div>
        )
      )}
    </div>
  );
}

export default Messages;

import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Registration from "./components/common/registration/Registration";
import Home from "./pages/Home";
import Login from "./components/common/login/Login";
import Profile from "./pages/Profile";
import Navbar from "./components/common/NavBar/NavBar";
import FooterNav from "./components/common/NavBar/FooterNav";
import Footer from "./components/common/Footer/Footer";
import ErrorPage from "./components/common/error-page/ErrorPage";
import Saved from "./pages/Saved";
import ForgotPassword from "./components/common/forgot-password/ForgotPassword";
import { useAuth } from "./context/AuthContext";
import { ThemeProvider } from "./context/ThemeContext";
import PostDetail from "./components/posts/PostDetail";
import RoomGallery from "./components/posts/RoomGallary";
import SphereView from "./components/posts/SphereView";
import Admin from "./admin/Admin";
import SearchPage from "./pages/SearchPage";
import Explore from "./pages/Explore";
import Messages from "./pages/Messages";
import RequestFormPage from "./pages/RequestFormPage";
import OutsideGallary from "./components/posts/OutsideGallary";
import SurroundingGallary from "./components/posts/SurroundingGallary";
import OutsideSphereView from "./components/posts/OutsideSphereView";
import SurroundingSphereView from "./components/posts/SurroundingSphereView";
import ContactButton from "./components/common/contact-button/ContactButton";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "./firebase";
import toast, { Toaster } from "react-hot-toast";
import NotificationSound from "./assets/sounds/notification.mp3";
import { X } from "lucide-react";

const Layout = ({ children }) => {
  const [conversations, setConversations] = useState([]);
  const { currentUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      const q = query(
        collection(db, "conversations"),
        where("customerId", "==", currentUser.uid),
        where("adminLastMessageStatus", "==", "unread")
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const unreadConvos = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setConversations(unreadConvos);
      });

      return () => unsubscribe();
    }
  }, [currentUser]);

  useEffect(() => {
    const handleTakeToMessags = () => {
      navigate("/messages");
    };

    const unreadConversations = conversations.filter(
      (conversation) => conversation.adminLastMessageStatus === "unread"
    );

    if (unreadConversations.length > 0) {
      const audio = new Audio(NotificationSound);
      audio.play().catch((error) => {
        console.error("Error playing sound:", error);
      });

      unreadConversations.forEach((conversation) => {
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-xl pointer-events-auto flex ring-1 ring-black ring-opacity-5 border border-gray-300 transition-transform transform duration-150 cursor-pointer`}
            onClick={handleTakeToMessags}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-0.5">
                  <img
                    className="h-14 w-14 rounded-full"
                    src={conversation.postImg}
                    alt="post"
                  />
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-mainColor">
                    New Message
                  </p>
                  <p className="mt-1 text-xs text-gray-600">
                    {conversation.postName}
                  </p>
                  <p className="mt-1 text-sm text-gray-600">
                    You have a new message from the admin!
                  </p>
                </div>
              </div>
            </div>
            <div className="flex">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  toast.dismiss(t.id);
                }}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-mainColor hover:bg-gray-200 transition-colors duration-150 focus:outline-none"
              >
                <X />
              </button>
            </div>
          </div>
        ));
      });
    }
  }, [conversations, navigate]);

  const hideNavbarOnPages = [
    "/login",
    "/register",
    "/forgot-password",
    "/admin",
    "/messages",
    "/request-form",
  ];

  const shouldHideNavbar = hideNavbarOnPages.some((path) =>
    location.pathname.startsWith(path)
  );

  const hideFooterOnPages = ["/admin", "/messages"];

  const shouldHideFooter = hideFooterOnPages.some((path) =>
    location.pathname.startsWith(path)
  );

  const hideFooterNavOnPages = ["/admin", "/messages"];

  const shouldHideFooterNav = hideFooterNavOnPages.some((path) =>
    location.pathname.startsWith(path)
  );

  const hideContactButtonOnPages = [
    "/login",
    "/register",
    "/forgot-password",
    "/admin",
    "/messages",
    "/request-form",
  ];

  const shouldHideContactButton = hideContactButtonOnPages.some((path) =>
    location.pathname.startsWith(path)
  );

  const unreadCount = conversations.filter(
    (conversation) => conversation.adminLastMessageStatus === "unread"
  ).length;

  return (
    <>
      {!shouldHideNavbar && <Navbar unreadCount={unreadCount} />}
      {children}
      {!shouldHideFooter && <Footer />}
      {!shouldHideContactButton && <ContactButton />}
      {!shouldHideFooterNav && <FooterNav unreadCount={unreadCount} />}
    </>
  );
};

function App() {
  const { currentUser } = useAuth();

  return (
    <ThemeProvider>
      <div className="relative bg-backgroundColor dark:bg-backgroundDark transition-colors duration-300 font-Quicksand">
        <Toaster />
        <Router>
          <Layout>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Registration />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/" element={<Home />} />
              <Route path="/explore" element={<Explore />} />
              <Route path="/post/:id" element={<PostDetail />} />
              <Route path="/search/:searchWord" element={<SearchPage />} />
              <Route
                path="/messages"
                element={currentUser ? <Messages /> : <Navigate to="/login" />}
              />
              <Route
                path="/request-form"
                element={
                  currentUser ? <RequestFormPage /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/saved"
                element={currentUser ? <Saved /> : <Navigate to="/login" />}
              />
              {/* <Route path="/post/:postId/:roomId" element={<RoomGallery />} /> */}
              <Route
                path="/post/:postId/outside"
                element={<OutsideGallary />}
              />
              <Route
                path="/post/:postId/surrounding"
                element={<SurroundingGallary />}
              />
              <Route
                path="/post/:postId/:roomId/3D-tour"
                element={<SphereView />}
              />
              <Route
                path="/post/:postId/outside/3D-tour"
                element={<OutsideSphereView />}
              />
              <Route
                path="/post/:postId/surrounding/3D-tour"
                element={<SurroundingSphereView />}
              />
              <Route
                path="/profile"
                element={currentUser ? <Profile /> : <Navigate to="/login" />}
              />

              {/* Admin routes */}
              <Route path="/admin/*" element={<Admin />} />
              <Route path="/*" element={<ErrorPage />} />
            </Routes>
          </Layout>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { db } from "../firebase";
import PostCard from "../components/posts/PostCard";
import Loader from "../components/common/Loader/Loader";

function Explore() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastVisible, setLastVisible] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const postsCollection = collection(db, "posts");
        const q = query(
          postsCollection,
          orderBy("createdAt", "desc"),
          limit(10)
        );
        const postsSnapshot = await getDocs(q);
        const postsList = postsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setPosts(postsList);
        setLastVisible(postsSnapshot.docs[postsSnapshot.docs.length - 1]);
      } catch (err) {
        console.error("Error fetching posts:", err);
        setError("Failed to load posts");
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const fetchMorePosts = async () => {
    if (isFetching || !lastVisible) return;

    setIsFetching(true);

    try {
      const postsCollection = collection(db, "posts");
      const q = query(
        postsCollection,
        orderBy("createdAt", "desc"),
        startAfter(lastVisible),
        limit(10)
      );

      const postsSnapshot = await getDocs(q);
      const postsList = postsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setPosts((prevPosts) => [...prevPosts, ...postsList]);
      setLastVisible(postsSnapshot.docs[postsSnapshot.docs.length - 1]);
    } catch (err) {
      console.error("Error fetching more posts:", err);
    } finally {
      setIsFetching(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div className="text-center text-xl font-bold py-20">{error}</div>;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-20">
      <h1 className="text-3xl font-bold mb-4 text-gray-900 dark:text-gray-100">
        Explore Posts
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {posts.map((post) => (
          <PostCard key={post.id} post={post} />
        ))}
      </div>
      {lastVisible && (
        <div className="flex justify-center mt-4">
          <button
            onClick={fetchMorePosts}
            className="px-4 py-2 bg-mainColor text-white rounded hover:bg-mainColorDark"
            disabled={isFetching}
          >
            {isFetching ? "Loading..." : "More Posts"}
          </button>
        </div>
      )}
    </div>
  );
}

export default Explore;

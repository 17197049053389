import React, { useState } from "react";
import { useAuth } from "../context/AuthContext";
import RequestBg from "../assets/image/requestBg.jpg";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import { TailSpin } from "react-loader-spinner";
import toast from "react-hot-toast";

function RequestFormPage() {
  const { currentUser, firstName, lastName } = useAuth();
  const [formData, setFormData] = useState({
    firstName: firstName,
    lastName: lastName,
    phoneNumber: "",
    city: "",
    locationName: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.firstName.trim()) {
      errors.firstName = "First name is required";
    }
    if (!formData.lastName.trim()) {
      errors.lastName = "Last name is required";
    }
    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = "Phone number is required";
    }
    if (!formData.city.trim()) {
      errors.city = "City is required";
    }
    if (!formData.locationName.trim()) {
      errors.locationName = "Location name is required";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      try {
        await addDoc(collection(db, "requests"), {
          uid: currentUser.uid,
          firstName: formData.firstName,
          lastName: formData.lastName,
          phoneNumber: formData.phoneNumber,
          city: formData.city,
          locationName: formData.locationName,
          message: formData.message,
          status: "pending",
          createdAt: serverTimestamp(),
        });
        setFormData({
          firstName: firstName,
          lastName: lastName,
          phoneNumber: "",
          city: "",
          locationName: "",
          message: "",
        });
        setFormErrors({});
        setLoading(false);
        toast.success("Request Submitted successfully.");
      } catch (error) {
        console.error("Error adding document: ", error);
        setLoading(false);
      }
    } else {
      setFormErrors(errors);
      setLoading(false);
    }
  };

  return (
    <div className="relative h-fit flex flex-col items-center justify-center py-20">
      <div
        className="absolute inset-0 bg-cover bg-center brightness-50"
        style={{ backgroundImage: `url(${RequestBg})` }}
      ></div>
      <div className="w-full max-w-xl bg-backgroundColor dark:bg-backgroundDark border border-gray-400 rounded-alg p-8 shadow-lg z-10">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100 mb-6 text-center">
          Property Request Form
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="flex space-x-4 mb-4">
            <div className="flex-1">
              <label className="block text-gray-700 dark:text-gray-300 mb-2">
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none dark:bg-gray-700 dark:text-gray-200"
              />
              {formErrors.firstName && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.firstName}
                </p>
              )}
            </div>

            <div className="flex-1">
              <label className="block text-gray-700 dark:text-gray-300 mb-2">
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none dark:bg-gray-700 dark:text-gray-200"
              />
              {formErrors.lastName && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.lastName}
                </p>
              )}
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 mb-2">
              Phone Number
            </label>
            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none dark:bg-gray-700 dark:text-gray-200"
            />
            {formErrors.phoneNumber && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.phoneNumber}
              </p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 mb-2">
              City
            </label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none dark:bg-gray-700 dark:text-gray-200"
            />
            {formErrors.city && (
              <p className="text-red-500 text-sm mt-1">{formErrors.city}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 mb-2">
              Location Name
            </label>
            <input
              type="text"
              name="locationName"
              value={formData.locationName}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none dark:bg-gray-700 dark:text-gray-200"
            />
            {formErrors.locationName && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.locationName}
              </p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 mb-2">
              Custom Message
            </label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              rows="4"
              className="w-full h-28 resize-none px-3 py-2 border border-gray-300 rounded-md focus:outline-none dark:bg-gray-700 dark:text-gray-200"
            />
          </div>

          <div>
            {loading ? (
              <div className="flex justify-center items-center text-center text-mainColor space-x-3">
                <TailSpin
                  visible={loading}
                  height="20"
                  width="20"
                  color="#00cafc"
                  ariaLabel="loading"
                />{" "}
                <span>Submitting...</span>
              </div>
            ) : (
              <button
                type="submit"
                disabled={loading}
                className="w-full bg-mainColor text-white py-2 rounded-lg font-semibold hover:bg-mainColor-dark transition-colors"
              >
                Submit Request
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default RequestFormPage;

import { useEffect, useState } from "react";
import { CheckCircle, Clock, ShoppingBag } from "lucide-react";
import { motion } from "framer-motion";
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  limit,
  Timestamp,
} from "firebase/firestore";

import Header from "../components/common/Header";
import StatCard from "../components/common/StatCard";
import RequestsTable from "../components/requests/RequestsTable";
import { db } from "../../firebase";

const RequestsPage = () => {
  const [requestsStats, setRequestsStats] = useState({
    totalRequests: 0,
    pendingRequests: 0,
    completedRequests: 0,
  });

  const [requestsData, setRequestsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const q = query(
      collection(db, "requests"),
      orderBy("createdAt", "desc"),
      limit(40)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      let totalRequests = 0;
      let pendingRequests = 0;
      let completedRequests = 0;
      const allRequests = [];

      snapshot.docs.forEach((doc) => {
        const data = doc.data();
        const createdAt =
          data.createdAt instanceof Timestamp
            ? data.createdAt.toDate().toISOString()
            : data.createdAt;
        allRequests.push({
          id: doc.id,
          ...data,
          createdAt,
        });

        totalRequests += 1;
        if (data.status === "pending") {
          pendingRequests += 1;
        } else if (data.status === "completed") {
          completedRequests += 1;
        }
      });

      setRequestsStats({
        totalRequests,
        pendingRequests,
        completedRequests,
      });

      setRequestsData(allRequests);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="flex-1 relative z-10 overflow-auto">
      <Header title={"Requests"} />

      <main className="max-w-7xl mx-auto py-6 px-4 lg:px-8">
        <motion.div
          className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <StatCard
            name="Total Requests"
            icon={ShoppingBag}
            value={requestsStats.totalRequests}
            color="#9933ff"
          />
          <StatCard
            name="Pending Requests"
            icon={Clock}
            value={requestsStats.pendingRequests}
            color="#F59E0B"
          />
          <StatCard
            name="Completed Requests"
            icon={CheckCircle}
            value={requestsStats.completedRequests}
            color="#10B981"
          />
        </motion.div>

        <div>
          <RequestsTable requestsData={requestsData} loading={loading} />
        </div>
      </main>
    </div>
  );
};

export default RequestsPage;

import React from "react";
import { useAuth } from "../../context/AuthContext";
import Moment from "react-moment";
import Linkify from "linkify-react";

function Message({ message }) {
  const { currentUser } = useAuth();
  const isOwnMessage = message.senderId === currentUser.uid;

  return (
    <div className="space-y-3 pb-5">
      <div
        className={`flex flex-col ${
          isOwnMessage ? "items-end" : "items-start"
        }`}
      >
        <div className="relative flex flex-row">
          {!isOwnMessage ? (
            <>
              <div className="absolute w-5 h-5 -left-[0.4rem] bottom-0 bg-gray-500 rounded-br-2xl"></div>
              <div className="absolute w-5 h-5 -left-[1.25rem] bottom-0 bg-backgroundColor dark:bg-backgroundDark rounded-br-xl transition-colors duration-300"></div>
            </>
          ) : (
            <>
              <div className="absolute w-5 h-5 -right-[0.4rem] bottom-0 bg-mainColor rounded-bl-2xl"></div>
              <div className="absolute w-5 h-5 -right-[1.25rem] bottom-0 bg-backgroundColor dark:bg-backgroundDark rounded-bl-xl transition-colors duration-300"></div>
            </>
          )}

          <div
            className={`max-w-xs lg:max-w-md py-2 px-4 ${
              isOwnMessage
                ? "bg-mainColor text-white rounded-2xl"
                : "bg-gray-500 dark:bg-gray-500 text-white rounded-2xl"
            }`}
          >
            <Linkify
              options={{
                attributes: {
                  style: {
                    color: "blue",
                    textDecoration: "underline",
                  },
                  target: "_blank",
                },
              }}
            >
              {message.message}
            </Linkify>
          </div>
        </div>

        <div
          className={`flex text-xs text-gray-400 font-medium mt-1 ${
            isOwnMessage ? "justify-end" : "justify-start"
          }`}
        >
          <Moment fromNow>{message.createdAt}</Moment>
        </div>
      </div>
    </div>
  );
}

export default Message;

import React from "react";
import Header from "../components/common/Header";
import { motion } from "framer-motion";
import StatCard from "../components/common/StatCard";
import {
  ClipboardList,
  Mail,
  MessageCircleMore,
  SquarePlus,
} from "lucide-react";
import CustomerSupport from "../../assets/image/Customer-Support.png";

function EmployeeOverview({ unreadCounts, firstName }) {
  const totalUnread =
    unreadCounts.conversations + unreadCounts.requests + unreadCounts.contacts;
  const progress = totalUnread === 0 ? 100 : Math.max(0, 100 - totalUnread);

  const progressColor = progress === 100 ? "bg-green-500" : "bg-red-500";

  return (
    <div className="flex-1 overflow-auto relative z-10">
      <Header title="Overview" />

      <main className="max-w-7xl mx-auto py-6 px-4 lg:px-8">
        {/* STATS */}
        <motion.div
          className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <StatCard
            name="Unread Messages"
            icon={MessageCircleMore}
            value={unreadCounts.conversations}
            color="#F59E0B"
          />
          <StatCard
            name="Pending Requests"
            icon={ClipboardList}
            value={unreadCounts.requests}
            color="#cc3300"
          />
          <StatCard
            name="Unread Contacts Messages"
            icon={Mail}
            value={unreadCounts.contacts}
            color="#00a3cc"
          />
          <StatCard
            name="Total Pending"
            icon={SquarePlus}
            value={totalUnread}
            color="#6EE7B7"
          />
        </motion.div>

        <motion.div
          className="bg-gray-800 bg-opacity-50 backdrop-blur-md overflow-hidden shadow-lg rounded-xl border border-gray-700"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.3 }}
        >
          <div className="px-4 py-5 sm:p-6 flex justify-center items-center flex-col">
            <h2 className="text-xl font-medium text-gray-400">
              Welcome {firstName} to Admin Pages!
            </h2>

            <div className="w-96 h-fit mb-4 flex items-center justify-center">
              <img
                src={CustomerSupport}
                alt="Customer Support"
                className="object-cover w-full h-full"
              />
            </div>

            <p className="mt-2 text-lg text-gray-100 text-center">
              This is your overview dashboard where you can keep track of all
              important tasks.
            </p>
            <p className="mt-2 text-base text-gray-400 text-center">
              Below are the unread messages, pending requests, and contact
              messages that need your attention. Stay productive!
            </p>

            <div className="w-full mt-4">
              <div className="flex justify-between mb-1">
                <span className="text-sm font-medium text-gray-400">
                  Tasks Progress
                </span>
                <span className="text-sm font-medium text-gray-400">
                  {progress}%
                </span>
              </div>
              <div className="w-full bg-gray-700 rounded-full h-2.5">
                <div
                  className={`${progressColor} h-2.5 rounded-full`}
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
          </div>
        </motion.div>
      </main>
    </div>
  );
}

export default EmployeeOverview;

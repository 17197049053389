import React, { useEffect, useState } from "react";
import { auth, db, googleProvider } from "../../../firebase";
import { signInWithPopup, signInWithEmailAndPassword } from "firebase/auth";
import { FaGoogle } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/image/logo.png";
import backg from "../../../assets/image/background.jpg";
import { GrHomeRounded } from "react-icons/gr";
import { TailSpin } from "react-loader-spinner";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleGoogleSignIn = async () => {
    setGoogleLoading(true);
    setMessage({ type: "", text: "" });

    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        await updateDoc(userRef, {
          lastLogin: new Date().toISOString(),
        });
      } else {
        await setDoc(userRef, {
          email: user.email,
          firstName: user.displayName?.split(" ")[0] || "",
          lastName: user.displayName?.split(" ")[1] || "",
          role: "user",
          createdAt: new Date().toISOString(),
          lastLogin: new Date().toISOString(),
        });
      }

      setMessage({ type: "success", text: "Google Sign-in successful" });
      navigate("/");
    } catch (error) {
      setMessage({ type: "error", text: error.message });
    } finally {
      setGoogleLoading(false);
    }
  };

  const handleEmailPasswordSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ type: "", text: "" });

    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      const user = result.user;

      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, {
        lastLogin: new Date().toISOString(),
      });

      setMessage({
        type: "success",
        text: "Email/Password Sign-in successful",
      });
      navigate("/");
    } catch (error) {
      setMessage({ type: "error", text: error.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="relative h-fit">
      <div className="absolute top-5 left-5 z-20">
        <button
          onClick={() => navigate("/")}
          className="fixed px-4 py-2 flex space-x-1 justify-center items-center text-sm text-black bg-gray-200 rounded-2xl hover:bg-gray-300 dark:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
        >
          <GrHomeRounded />{" "}
          <span className="lg:text-sm text-xs">Back to Home</span>
        </button>
      </div>
      <div
        className="absolute inset-0 bg-cover bg-center brightness-50"
        style={{ backgroundImage: `url(${backg})` }}
      ></div>
      <div className="flex flex-col items-center justify-center px-6 py-16 lg:py-10 mx-auto h-full relative z-10">
        <div className="flex justify-center items-center space-x-3 mb-5">
          <img src={logo} alt="Logo" className="h-16" />
          <p className="font-semibold text-gray-700 text-2xl dark:text-white">
            Welcome Back
          </p>
        </div>
        <div className="w-full bg-backgroundColor dark:bg-backgroundDark border border-gray-400 rounded-alg max-w-lg xl:p-0">
          <div className="p-6 lg:m-5 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-gray-100 md:text-2xl">
              Log in to your account
            </h1>

            {message.text && (
              <div
                className={`mb-4 p-3 rounded ${
                  message.type === "success"
                    ? "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-100"
                    : "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-100"
                }`}
              >
                {message.text}
              </div>
            )}

            <form
              className="space-y-4 md:space-y-6"
              onSubmit={handleEmailPasswordSignIn}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="name@company.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  required
                />
              </div>

              <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                Forgot your password?{" "}
                <Link
                  to="/forgot-password"
                  className="font-medium text-mainColor hover:underline"
                >
                  Reset Password
                </Link>
              </p>

              <button
                type="submit"
                className="w-full text-gray-500 hover:text-gray-600 font-medium bg-primary-600 hover:bg-primary-700 border-2 border-gray-500 hover:border-gray-600 focus:outline-none rounded-lg text-sm px-5 py-2.5 dark:text-gray-300 dark:border-gray-600 dark:hover:bg-primary-700"
                disabled={loading || googleLoading}
              >
                {loading ? (
                  <div className="flex justify-center items-center text-center text-mainColor space-x-3">
                    <TailSpin
                      visible={loading}
                      height="20"
                      width="20"
                      color="#00cafc"
                      ariaLabel="loading"
                    />{" "}
                    <span>Logging In...</span>
                  </div>
                ) : (
                  "Log in"
                )}
              </button>
            </form>

            <div className="relative mt-6 text-center">
              <div className="absolute inset-0 flex justify-between flex-row items-center">
                <div className="w-1/3 border-t border-gray-400 dark:border-gray-600"></div>
                <div className="w-1/3 border-t border-gray-400 dark:border-gray-600"></div>
              </div>
              <div className="relative px-4 text-gray-500 dark:text-gray-400">
                or
              </div>
            </div>

            <button
              onClick={handleGoogleSignIn}
              disabled={loading || googleLoading}
              className="w-full py-2 rounded-lg bg-mainColor text-white font-medium hover:bg-hovermainColor flex items-center justify-center focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {googleLoading ? (
                <div className="flex justify-center items-center text-center text-white space-x-3">
                  <TailSpin
                    visible={googleLoading}
                    height="20"
                    width="20"
                    color="#fff"
                    ariaLabel="loading"
                  />{" "}
                  <span>Logging in with Google...</span>
                </div>
              ) : (
                <>
                  <FaGoogle className="h-5 w-5 mr-2" />
                  Log in with Google
                </>
              )}
            </button>

            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
              Don't have an account?{" "}
              <Link
                to="/register"
                className="font-medium text-mainColor hover:underline"
              >
                Register
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;

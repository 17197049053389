import { motion } from "framer-motion";
import Header from "../components/common/Header";
import StatCard from "../components/common/StatCard";
import { AlertTriangle, FileImage, FilePlus, TrendingUp } from "lucide-react";
import CategoryDistributionChart from "../components/overview/CategoryDistributionChart";
import RequestTrendChart from "../components/posts/RequestTrendChart";
import PostsTable from "../components/posts/PostsTable";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

const PostsPage = () => {
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const postsCollection = collection(db, "posts");
      const postsSnapshot = await getDocs(postsCollection);
      const postsList = postsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPosts(postsList);
      setFilteredPosts(postsList);
    };

    fetchPosts();
  }, []);

  const totalPosts = posts.length;

  return (
    <div className="flex-1 overflow-auto relative z-10">
      <Header title="Posts" />

      <main className="max-w-7xl mx-auto py-6 px-4 lg:px-8">
        <motion.div
          className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <StatCard
            name="Total Posts"
            icon={FileImage}
            value={totalPosts}
            color="#6366F1"
          />
          <StatCard
            name="Top Requested"
            icon={TrendingUp}
            value={89}
            color="#10B981"
          />
          <StatCard
            name="Least Requested"
            icon={AlertTriangle}
            value={23}
            color="#F59E0B"
          />
          <Link
            to="/admin/create-post"
            className="bg-gray-900 rounded-alg text-white flex space-x-4 justify-center items-center"
          >
            <FilePlus /> <span>Create a Post</span>
          </Link>
        </motion.div>

        <PostsTable posts={posts} filteredPosts={filteredPosts} />

        <div className="grid grid-col-1 lg:grid-cols-2 gap-8">
          <RequestTrendChart />
          <CategoryDistributionChart />
        </div>
      </main>
    </div>
  );
};

export default PostsPage;

import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function DetailSection({ post, toggleRoomGallery, id }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const getDescription = () => {
    const descriptions = post.description.split("|");

    const languageMap = {
      en: descriptions[0],
      am: descriptions[1],
      ar: descriptions[2],
    };

    return languageMap[language] || descriptions[0];
  };

  const getName = () => {
    const Names = post.name.split("|");

    const languageMap = {
      en: Names[0],
      am: Names[1],
      ar: Names[2],
    };

    return languageMap[language] || Names[0];
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <div className="flex-1">
      <h1 className="text-3xl text-black dark:text-gray-300 font-medium mb-2">
        {getName()}
      </h1>
      <p className="text-gray-600 dark:text-gray-300 text-lg mb-4">
        {post.location.name}
      </p>
      <div className="flex items-center mb-4">
        {post.purchaseType === "Rent" ? (
          <div className="flex justify-center items-center space-x-3">
            <p className="text-xl font-semibold text-green-600">
              {new Intl.NumberFormat().format(post.rentPrice)} {t("birr")} /{" "}
              {post.rentPaymentType === "yearly" ? t("yearly") : t("monthly")}
            </p>
            <p className="text-black font-semibold dark:text-gray-200 py-1 px-2 dark:bg-gray-500 bg-gray-400 rounded-lg flex justify-center items-center text-center ">
              {t("for_rent")}
            </p>
          </div>
        ) : (
          <div className="flex justify-center items-center space-x-3">
            <p className="text-2xl font-semibold text-green-600">
              {new Intl.NumberFormat().format(post.salePrice)} {t("birr")}
            </p>
            <p className="text-black font-semibold dark:text-gray-200 py-1 px-2 dark:bg-gray-500 bg-gray-400 rounded-lg flex justify-center items-center text-center ">
              {t("for_sale")}
            </p>
          </div>
        )}
      </div>

      <div className="p-4 rounded-xl mb-4 shadow border border-gray-300 dark:border-gray-700">
        <h2 className="text-xl text-black font-semibold dark:text-gray-300 mb-3">
          About this Property
        </h2>
        <p
          className="text-gray-700 dark:text-gray-300"
          dangerouslySetInnerHTML={{ __html: getDescription() }}
        ></p>
        <p className="text-gray-600 dark:text-gray-400 mt-2">
          By: {post.propertyOwner.name} - {formatDate(post.createdAt)}
        </p>
      </div>

      <div className="p-6 rounded-xl mb-6 shadow border border-gray-300 dark:border-gray-700">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-200 mb-5">
          Room Details
        </h2>
        <ul className="space-y-6">
          {post.rooms.map((room) => {
            const totalImagesCount = room.instances.reduce(
              (acc, instance) => acc + instance.normalImages.length,
              0
            );
            const moreImagesCount = totalImagesCount - 2;

            const getRoomType = () => {
              const RooomType = room.roomType.split("|");

              const languageMap = {
                en: RooomType[0],
                am: RooomType[1],
                ar: RooomType[2],
              };

              return languageMap[language] || RooomType[0];
            };

            return (
              <li
                key={room.roomId}
                className="bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 p-4 rounded-xl flex flex-col lg:flex-row lg:items-center justify-between shadow-sm space-y-4 lg:space-y-0 lg:space-x-6"
              >
                <div className="flex-1">
                  <div className="flex items-center">
                    <p className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                      {getRoomType()} : {room.roomInstancesCount}
                    </p>
                    <span className="text-black font-bold text-lg dark:text-gray-400"></span>
                  </div>
                  <button
                    onClick={() => toggleRoomGallery(room.roomId)}
                    className="mt-2 inline-block text-blue-600 dark:text-blue-400 underline"
                  >
                    View Gallery
                  </button>
                </div>

                <div
                  onClick={() => toggleRoomGallery(room.roomId)}
                  className="grid grid-cols-2 gap-4 lg:w-1/2 relative"
                >
                  {room.instances[0].normalImages
                    .slice(0, 2)
                    .map((image, index) => (
                      <div
                        key={`${room.instances[0].instanceId}-${index}`}
                        className="relative"
                      >
                        <img
                          src={image}
                          alt={`${room.roomType} pic ${index + 1}`}
                          className="w-full h-32 object-cover rounded-lg shadow-sm dark:shadow-md cursor-pointer"
                        />

                        {index === 1 && moreImagesCount > 0 && (
                          <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg flex items-center justify-center cursor-pointer">
                            <span className="text-white text-lg font-bold">
                              +{moreImagesCount} more
                            </span>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="p-6 rounded-xl mb-6 shadow border border-gray-300 dark:border-gray-700">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-200 mb-5">
          Outside and Surrounding Details
        </h2>
        <ul className="space-y-6">
          <li className="bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 p-4 rounded-xl flex flex-col lg:flex-row lg:items-center justify-between shadow-sm space-y-4 lg:space-y-0 lg:space-x-6">
            <div className="flex-1">
              <div className="flex items-center space-x-4">
                <p className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                  Outside
                </p>
              </div>
              <Link
                to={`/post/${id}/outside`}
                className="mt-2 inline-block text-blue-600 dark:text-blue-400 underline"
              >
                View Gallery
              </Link>
            </div>

            <Link
              to={`/post/${id}/outside`}
              className="grid grid-cols-2 gap-4 lg:w-1/2 relative"
            >
              {post.outside.normalImages.slice(0, 2).map((image, index) => (
                <div key={`outside-${index}`} className="relative">
                  <img
                    src={image}
                    alt={`Outside pic ${index + 1}`}
                    className="w-full h-32 object-cover rounded-lg shadow-sm dark:shadow-md cursor-pointer"
                  />

                  {index === 1 && post.outside.normalImages.length > 2 && (
                    <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg flex items-center justify-center">
                      <span className="text-white text-lg font-bold">
                        +{post.outside.normalImages.length - 2} more
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </Link>
          </li>

          <li className="bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 p-4 rounded-xl flex flex-col lg:flex-row lg:items-center justify-between shadow-sm space-y-4 lg:space-y-0 lg:space-x-6">
            <div className="flex-1">
              <div className="flex items-center space-x-4">
                <p className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                  Surrounding
                </p>
              </div>
              <Link
                to={`/post/${id}/surrounding`}
                className="mt-2 inline-block text-blue-600 dark:text-blue-400 underline"
              >
                View Gallery
              </Link>
            </div>

            <Link
              to={`/post/${id}/surrounding`}
              className="grid grid-cols-2 gap-4 lg:w-1/2 relative"
            >
              {post.surrounding.normalImages.slice(0, 2).map((image, index) => (
                <div key={`surrounding-${index}`} className="relative">
                  <img
                    src={image}
                    alt={`Surrounding pic ${index + 1}`}
                    className="w-full h-32 object-cover rounded-lg shadow-sm dark:shadow-md cursor-pointer"
                  />

                  {index === 1 && post.surrounding.normalImages.length > 2 && (
                    <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg flex items-center justify-center">
                      <span className="text-white text-lg font-bold">
                        +{post.surrounding.normalImages.length - 2} more
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default DetailSection;

import { UserCheck, UserPlus, UsersIcon } from "lucide-react";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import Header from "../components/common/Header";
import StatCard from "../components/common/StatCard";
import UsersTable from "../components/users/UsersTable";
import { Modal } from "antd";

const UsersPage = ({ role }) => {
  const [users, setUsers] = useState([]);
  const [newUsersToday, setNewUsersToday] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, "users");
      const usersSnapshot = await getDocs(usersCollection);
      const usersList = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setUsers(usersList);
      calculateStats(usersList);
    };

    fetchUsers();
  }, []);

  const calculateStats = (usersList) => {
    const today = new Date();
    const startOfToday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    ).getTime();

    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(today.getDate() - 14);
    const twoWeeksAgoTimestamp = twoWeeksAgo.getTime();

    const activeUsersCount = usersList.filter((user) => {
      const lastLogin = new Date(user.lastLogin);
      return lastLogin.getTime() >= twoWeeksAgoTimestamp;
    }).length;

    const newUsersCount = usersList.filter((user) => {
      const createdAt = new Date(user.createdAt);
      return createdAt.getTime() >= startOfToday;
    }).length;

    setNewUsersToday(newUsersCount);
    setActiveUsers(activeUsersCount);
  };

  const updateUserRole = async (userId, newRole) => {
    try {
      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, { role: newRole });
      console.log("User role updated successfully");

      const usersCollection = collection(db, "users");
      const usersSnapshot = await getDocs(usersCollection);
      const usersList = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersList);
    } catch (error) {
      console.error("Error updating user role:", error);
    }
  };

  return (
    <div className="flex-1 overflow-auto relative z-10">
      <Header title="Users" />

      <main className="max-w-7xl mx-auto py-6 px-4 lg:px-8">
        {/* STATS */}
        <motion.div
          className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <StatCard
            name="Total Users"
            icon={UsersIcon}
            value={users.length.toLocaleString()}
            color="#6366F1"
          />
          <StatCard
            name="New Users Today"
            icon={UserPlus}
            value={newUsersToday}
            color="#10B981"
          />
          <StatCard
            name="Active Users"
            icon={UserCheck}
            value={activeUsers.toLocaleString()}
            color="#F59E0B"
          />
        </motion.div>

        <UsersTable users={users} role={role} updateUserRole={updateUserRole} />
      </main>
    </div>
  );
};

export default UsersPage;

import React, { useEffect, useState } from "react";
import { HiMiniBookmark, HiOutlineBookmark } from "react-icons/hi2";
import { db } from "../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { useAuth } from "../context/AuthContext";
import PostCard from "../components/posts/PostCard";
import Loader from "../components/common/Loader/Loader";

function Saved() {
  const { currentUser } = useAuth();
  const [savedPosts, setSavedPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSavedPosts = async () => {
      setLoading(true);

      try {
        const savedCollectionRef = collection(db, "saved");
        const q = query(
          savedCollectionRef,
          where("uid", "==", currentUser.uid),
          orderBy("createdAt", "desc")
        );
        const savedSnapshot = await getDocs(q);
        const postPromises = [];

        savedSnapshot.forEach((savedDoc) => {
          const postRef = doc(db, "posts", savedDoc.id);
          postPromises.push(getDoc(postRef));
        });

        const posts = await Promise.all(postPromises);
        const postData = posts
          .filter((post) => post.exists())
          .map((post) => ({ id: post.id, saved: true, ...post.data() }));

        setSavedPosts(postData);
      } catch (error) {
        console.error("Error fetching saved posts:", error);
      } finally {
        setLoading(false);
      }
    };

    if (currentUser?.uid) {
      fetchSavedPosts();
    }
  }, [currentUser]);

  const handleSaveClick = async (postId, isSaved) => {
    const savedRef = doc(db, "saved", postId);

    try {
      if (isSaved) {
        await deleteDoc(savedRef);
      } else {
        await setDoc(savedRef, {
          uid: currentUser.uid,
          createdAt: new Date().toISOString(),
        });
      }
      setSavedPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postId ? { ...post, saved: !isSaved } : post
        )
      );
    } catch (error) {
      console.error("Error saving/unsaving post:", error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="font-Quicksand py-20 px-6 sm:px-10">
      <div>
        <div className="flex items-center space-x-2 mb-10 lg:mx-5">
          <HiMiniBookmark className="text-mainColor text-2xl" />
          <h1 className="text-2xl dark:text-gray-200 text-gray-800 font-bold ml-4 sm:ml-6">
            Saved Posts
          </h1>
        </div>
        <div className="space-y-7">
          {savedPosts.length === 0 ? (
            <div className="py-20 lg:py-40 flex justify-center items-center lg:mx-auto">
              <p className="text-xl text-gray-800 dark:text-gray-200 font-semibold">
                No saved posts found.
              </p>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 lg:mx-5">
              {savedPosts.map((post) => (
                <div key={post.id} className="relative">
                  <PostCard key={post.id} post={post} />
                  <button
                    className="absolute right-0 bottom-8 p-2 bg-gray-300 rounded-full"
                    onClick={() => handleSaveClick(post.id, post.saved)}
                  >
                    {post.saved ? (
                      <HiMiniBookmark className="text-mainColor text-xl" />
                    ) : (
                      <HiOutlineBookmark className="text-gray-700 text-xl hover:text-gray-800" />
                    )}
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Saved;

import {
  BarChart2,
  FileImage,
  MessageCircleMore,
  ShoppingBag,
  Users,
  Zap,
} from "lucide-react";
import { motion } from "framer-motion";

import Header from "../components/common/Header";
import StatCard from "../components/common/StatCard";
import SalesOverviewChart from "../components/overview/SalesOverviewChart";
import CategoryDistributionChart from "../components/overview/CategoryDistributionChart";

const OverviewPage = () => {
  return (
    <div className="flex-1 overflow-auto relative z-10">
      <Header title="Overview" />

      <main className="max-w-7xl mx-auto py-6 px-4 lg:px-8">
        {/* STATS */}
        <motion.div
          className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <StatCard
            name="Total Sales"
            icon={Zap}
            value="1,245"
            color="#10B981"
          />
          <StatCard
            name="New Users"
            icon={Users}
            value="1,234"
            color="#EC4899"
          />
          <StatCard
            name="Total Posts"
            icon={FileImage}
            value="567"
            color="#8B5CF6"
          />
          <StatCard
            name="Total Requests"
            icon={MessageCircleMore}
            value="1,500"
            color="#F59E0B"
          />
        </motion.div>

        {/* CHARTS */}

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <SalesOverviewChart />
          <CategoryDistributionChart />
        </div>
      </main>
    </div>
  );
};
export default OverviewPage;

import React, { useEffect, useState, useContext } from "react";
import { Modal, message } from "antd";
import { db } from "../../../firebase";
import { FaTimes } from "react-icons/fa";
import { TailSpin } from "react-loader-spinner";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ThemeContext } from "../../../context/ThemeContext";

const ContactInfo = ({ currentUser }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editloading, setEditloading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { theme } = useContext(ThemeContext);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
  });

  const isDarkMode = theme === "dark";

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const userRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserData(data);
          setFormData({
            firstName: data.firstName || "",
            lastName: data.lastName || "",
            phone: data.phone || "",
          });
        } else {
          message.error("User not found.");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        message.error("Failed to fetch user data.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [currentUser.uid]);

  const handleEdit = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEditloading(true);
    try {
      const userRef = doc(db, "users", currentUser.uid);
      await updateDoc(userRef, formData);
      setUserData(formData);
      message.success("Profile updated successfully");
      setIsModalVisible(false);
      setEditloading(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      message.error("Failed to update profile");
      setEditloading(false);
    }
  };

  return (
    <div className="lg:mb-0 mb-8 text-gray-800 dark:text-gray-200">
      <div className="flex flex-col md:flex-row items-center mt-20 md:mb-8 pb-5 border-b-2 border-gray-300 dark:border-gray-700">
        <div className="relative mb-5 md:mb-0 md:mr-8">
          {loading ? (
            <div className="flex justify-center items-center text-center text-mainColor dark:text-gray-200 space-x-3">
              <TailSpin
                visible={loading}
                height="80"
                width="80"
                color="#00cafc"
                ariaLabel="loading"
              />
            </div>
          ) : (
            <div className="h-36 w-36 rounded-full bg-gradient-to-r from-sky-400 to-purple-400 flex items-center justify-center text-7xl text-white font-semibold select-none">
              {userData?.firstName?.charAt(0)}
            </div>
          )}
        </div>

        <div className="flex-grow text-center md:text-left">
          {loading ? (
            <div className="flex justify-center items-center text-center text-mainColor dark:text-gray-200 space-x-3">
              <TailSpin
                visible={loading}
                height="20"
                width="20"
                color="#00cafc"
                ariaLabel="loading"
              />
            </div>
          ) : (
            <>
              <div className="flex flex-col md:flex-row items-center justify-between md:mb-5">
                <h1 className="text-2xl md:text-3xl font-bold text-gray-800 dark:text-white mb-2 md:mb-0 md:mr-4">
                  {userData?.firstName || ""} {userData?.lastName || ""}
                </h1>
                {currentUser && (
                  <button
                    onClick={handleEdit}
                    className="px-4 py-2 text-sm bg-mainColor text-white rounded-md hover:bg-hovermainColor dark:hover:bg-hovermainColor transition ease-in-out duration-300 flex items-center select-none"
                  >
                    Edit Profile
                  </button>
                )}
              </div>
              <p className="text-lg text-gray-600 dark:text-gray-400 mt-5 md:mt-0">
                {currentUser?.email || ""}
              </p>
              <p className="text-lg text-gray-600 dark:text-gray-400 mt-5 md:mt-0">
                {userData?.phone || (
                  <span className="lg:ml-2 text-gray-300 dark:text-gray-500">
                    No Phone number
                  </span>
                )}
              </p>
            </>
          )}
        </div>
      </div>

      <Modal
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        closeIcon={
          <FaTimes className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100" />
        }
        styles={{
          content: {
            backgroundColor: isDarkMode ? "#1f2937" : "#ffffff",
            borderRadius: "20px",
          },
        }}
      >
        <div className="font-Quicksand w-full bg-transparent max-w-lg xl:p-0">
          <div className="lg:m-1 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-gray-100 md:text-2xl">
              Editing Profile
            </h1>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-semibold text-gray-700 dark:text-gray-300"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full border border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white rounded-lg focus:ring-mainColor focus:border-mainColor dark:focus:border-hovermainColor dark:focus:ring-hovermainColor transition ease-in-out duration-200 p-2"
                  placeholder="First Name"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-semibold text-gray-700 dark:text-gray-300"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full border border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white rounded-lg focus:ring-mainColor focus:border-mainColor dark:focus:border-hovermainColor dark:focus:ring-hovermainColor transition ease-in-out duration-200 p-2"
                  placeholder="Last Name"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="phone"
                  className="block text-sm font-semibold text-gray-700 dark:text-gray-300"
                >
                  Phone Number
                </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  pattern="^[0-9]{10}$"
                  title="Please enter a valid 10-digit phone number"
                  className="mt-1 block w-full border border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white rounded-lg focus:ring-mainColor focus:border-mainColor dark:focus:border-hovermainColor dark:focus:ring-hovermainColor transition ease-in-out duration-200 p-2"
                  placeholder="Phone Number"
                />
              </div>

              <div className="mt-6">
                {editloading ? (
                  <div className="w-full flex justify-center items-center space-x-4 bg-mainColor hover:bg-hovermainColor text-white font-semibold py-2 rounded-lg transition ease-in-out duration-200 select-none">
                    <TailSpin
                      visible={editloading}
                      height="20"
                      width="20"
                      color="#fff"
                      ariaLabel="loading"
                    />
                    <span>Saving Changes...</span>
                  </div>
                ) : (
                  <button
                    type="submit"
                    disabled={editloading}
                    className="w-full bg-mainColor hover:bg-hovermainColor text-white font-semibold py-2 rounded-lg transition ease-in-out duration-200 select-none"
                  >
                    Save Changes
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ContactInfo;

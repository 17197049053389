import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GrLanguage } from "react-icons/gr";
import { IoMoon, IoSearch, IoSunny } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";
import { Dropdown, message } from "antd";
import {
  UserOutlined,
  MessageOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { FaRegBookmark } from "react-icons/fa6";
import logoIcon from "../../../assets/image/logo.png";
import LoginModal from "../login-modal/LoginModal";
import { auth } from "../../../firebase";
import { signOut } from "firebase/auth";
import { TailSpin } from "react-loader-spinner";
import toast from "react-hot-toast";
import { ThemeContext } from "../../../context/ThemeContext";
import { IoMdLogOut } from "react-icons/io";
import { useAuth } from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";

const ProfileMenuItems = (handleLogout, unreadCount, t) => [
  {
    label: (
      <Link className="font-Quicksand" to="/profile">
        {t("profile")} {/* Translated label */}
      </Link>
    ),
    key: "profile",
    icon: <UserOutlined />,
  },

  {
    label: (
      <Link className="font-Quicksand" to="/saved">
        {t("saved")} {/* Translated label */}
      </Link>
    ),
    key: "saved",
    icon: <FaRegBookmark />,
  },
  {
    label: (
      <Link
        className="font-Quicksand flex space-x-2 justify-between items-center"
        to="/messages"
      >
        {t("messages")} {/* Translated label */}
        {unreadCount > 0 && (
          <div className="p-1 min-w-4 h-4 items-center justify-center text-center text-gray-200 rounded-full flex bg-red-500 text-xs ml-4">
            {unreadCount}
          </div>
        )}
      </Link>
    ),
    key: "messages",
    icon: <MessageOutlined />,
  },

  {
    label: t("logout"),
    key: "logout",
    danger: true,
    icon: <LogoutOutlined />,
    onClick: handleLogout,
  },
];

const LangMenuItems = [
  { label: <span className="font-Quicksand">English</span>, key: "en" },
  { label: <span className="font-Quicksand">አማርኛ</span>, key: "am" },
  { label: <span className="font-Quicksand">اَلْعَرَبِيَّةُ</span>, key: "ar" },
];

const Logo = ({ scrolled, changeLanguage }) => (
  <div
    className={`font-Quicksand flex items-center space-x-4 transition-all duration-300 select-none ${
      scrolled ? "order-0" : "justify-center"
    }`}
  >
    <Link to="/" className="z-20">
      <img
        src={logoIcon}
        alt="Logo"
        className={`hidden lg:block h-14 transition-transform duration-300 ease-in-out transform ${
          scrolled
            ? "translate-x-0 scale-100 opacity-100 lg:flex hidden"
            : "opacity-0 scale-0"
        }`}
      />
    </Link>
    <Dropdown
      menu={{ items: LangMenuItems, onClick: changeLanguage }}
      trigger={["click"]}
      arrow
    >
      <button
        className={`flex items-center space-x-2 lg:p-2 rounded-2xl text-hovermainColor text-xl hover:bg-sky-200 transition-colors duration-300 font-semibold z-20 ${
          scrolled
            ? "lg:translate-x-0 -translate-x-1 scale-100 opacity-100 mt-3"
            : "lg:-translate-x-16 lg:translate-y-0 translate-x-2 translate-y-2 scale-100 opacity-100"
        }`}
      >
        <GrLanguage />
      </button>
    </Dropdown>
  </div>
);

const LoginButton = ({ showLoginModal, theme, toggleTheme, t }) => (
  <>
    <button
      onClick={showLoginModal}
      className={`font-Quicksand flex mt-3 px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-300 bg-mainColor text-white hover:bg-hovermainColor w-20 lg:w-24 h-10 items-center justify-center text-center select-none z-20`}
    >
      {t("login")}
    </button>
    <Link
      to="/register"
      className={`font-Quicksand hidden lg:block mt-3 px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-300 text-mainColor border-2 border-mainColor hover:bg-hovermainColor hover:border-hovermainColor hover:text-white w-24 h-10 text-center z-20`}
    >
      {t("signup")}
    </Link>

    <div className="lg:flex items-center space-x-4 mt-3 p-2 hidden z-20 justify-center">
      <button
        onClick={toggleTheme}
        className="text-2xl focus:outline-none transition-transform transform duration-500 dark:rotate-0 rotate-180"
      >
        {theme === "dark" ? (
          <IoSunny className="text-yellow-300" />
        ) : (
          <IoMoon className="rotate-180" />
        )}
      </button>
    </div>
  </>
);

const Navbar = ({ unreadCount }) => {
  const { t, i18n } = useTranslation();
  const [scrolled, setScrolled] = useState(false);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const { firstName, loading } = useAuth();
  const [user, setUser] = useState(null);
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [searchTerm, setSearchTerm] = useState("");

  const changeLanguage = (e) => {
    i18n.changeLanguage(e.key);
  };

  const navigate = useNavigate();

  const handleSearch = () => {
    if (searchTerm.trim()) {
      navigate(`/search/${encodeURIComponent(searchTerm)}`);
    }
  };

  const showLoginModal = () => {
    setIsLoginModalVisible(true);
  };
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 40);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      toast.success("Logged out successfully", {
        position: "top-center",
      });
    } catch (error) {
      toast.error("Failed to log out", {
        position: "top-center",
      });
    }
  };

  const profileMenu = ProfileMenuItems(handleLogout, unreadCount, t);

  return (
    <nav
      className={`font-Quicksand text-black p-2 flex flex-col space-y-2 sticky top-0 w-full transition-all duration-300 z-20 ${
        scrolled
          ? "shadow-md max-h-20 lg:max-w-5xl lg:mx-auto lg:rounded-[20px] rounded-b-lg lg:top-[9px] top-0 backdrop-blur-xl bg-white/30 backdrop-brightness-90 lg:backdrop-brightness-[.8]"
          : " max-h-20 bg-transparent"
      }`}
    >
      <div className="flex items-center justify-between lg:mx-5">
        <Logo scrolled={scrolled} changeLanguage={changeLanguage} />
        <Link
          to="/"
          className={`flex items-center space-x-1 transition-transform duration-300 ease-in-out transform ${
            scrolled
              ? "opacity-0 scale-0"
              : "translate-x-0 scale-100 opacity-100"
          }`}
        >
          <img
            src={logoIcon}
            alt="Logo"
            className={`select-none ${user ? "ml-0" : "ml-12"} lg:ml-44 h-12`}
          />
          <span className="text-2xl font-bold text-mainColor select-none">
            Find<span className="text-lg">Homes</span>
          </span>
          <div className="w-5"> </div>
        </Link>
        <div
          className={`flex items-center space-x-4 ${scrolled ? "order-2" : ""}`}
        >
          {user ? (
            <>
              <Link
                to="request-form"
                className="lg:flex hidden items-center justify-center text-xl mt-2 z-20 text-gray-900 dark:text-gray-200 hover:bg-gray-400 dark:hover:bg-gray-500  p-2 rounded-full"
              >
                <FaPlus />
              </Link>
              <div className="lg:flex hidden items-center mt-2 space-x-4 z-20">
                <button
                  onClick={toggleTheme}
                  className="text-2xl focus:outline-none transition-transform transform duration-500 dark:rotate-0 rotate-180"
                >
                  {theme === "dark" ? (
                    <IoSunny className="text-yellow-300" />
                  ) : (
                    <IoMoon className="rotate-180" />
                  )}
                </button>
              </div>
              <span
                className={`hidden lg:block mt-2 text-sm select-none dark:text-white ${
                  !scrolled && location.pathname === "/"
                    ? "text-gray-100"
                    : "text-black"
                }`}
              >
                {loading ? (
                  <div className="flex justify-center items-center text-center text-mainColor space-x-3">
                    <TailSpin
                      visible={loading}
                      height="20"
                      width="20"
                      color="#00cafc"
                      ariaLabel="loading"
                    />{" "}
                  </div>
                ) : (
                  <div>
                    {t("welcome")}, {firstName}
                  </div>
                )}
              </span>

              <Dropdown menu={{ items: profileMenu }} trigger={["click"]} arrow>
                <div className="lg:flex relative hidden items-center space-x-2 hover:cursor-pointer z-20 cursor-pointer">
                  {unreadCount > 0 && (
                    <div className="absolute rounded-full right-0 top-4 text-center text-white font-semibold bg-red-500 text-[0.6rem] min-w-2 h-2 flex items-center justify-center hover:shadow-lg z-30" />
                  )}
                  <div className="relative w-12 h-12 rounded-full flex items-center justify-center text-center mt-2">
                    <div className="h-10 w-10 rounded-full bg-gradient-to-r from-purple-400 to-blue-500 flex items-center justify-center text-white font-semibold select-none">
                      {firstName ? firstName.charAt(0) : "?"}
                    </div>

                    <div className="absolute inset-0 border-2 border-gray-400 hover:shadow-lg rounded-full"></div>
                  </div>
                </div>
              </Dropdown>
              <button
                className="lg:hidden absolute rounded-full top-6 right-2 text-center text-white font-semibold bg-red-500 p-2 text-xl flex items-center justify-center"
                onClick={handleLogout}
              >
                {" "}
                <IoMdLogOut />
              </button>
            </>
          ) : (
            <LoginButton
              theme={theme}
              toggleTheme={toggleTheme}
              showLoginModal={showLoginModal}
              t={t}
            />
          )}
        </div>
      </div>

      <div
        className={`flex flex-row items-center justify-center mx-10 transition-all duration-300 ${
          scrolled ? "order-1 mx-0 transform -translate-y-full top-0" : ""
        }`}
      >
        <div
          className={`relative flex items-center transition-all duration-300 ${
            scrolled
              ? "max-w-md lg:h-14 h-16 mr-0 lg:-left-20 -top-0 lg:-top-[12px]"
              : "max-w-2xl"
          } w-full ${scrolled && !user ? "mr-14 lg:mr-0" : "mr-0 mt-5"}`}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSearch();
            }}
            className="w-full"
          >
            <input
              type="search"
              placeholder={t("search_placeholder")}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="p-3 pl-8 pr-12 border border-gray-300 rounded-2xl bg-white text-black dark:bg-gray-800 dark:text-white text-base w-full shadow-md focus:outline-none focus:border-gray-400 transition-colors duration-300"
            />
            <button
              onClick={handleSearch}
              className="absolute right-[0.3rem] top-1/2 transform -translate-y-1/2 p-3 bg-mainColor rounded-[0.8rem] hover:bg-hovermainColor transition-colors duration-300"
            >
              <IoSearch className="text-white" />
            </button>
          </form>
        </div>
      </div>
      <LoginModal
        isLoginModalVisible={isLoginModalVisible}
        setIsLoginModalVisible={setIsLoginModalVisible}
      />
    </nav>
  );
};

export default Navbar;

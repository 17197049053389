import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Search, Check } from "lucide-react";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";

function MessagesTable({ messagesData }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMessages, setFilteredMessages] = useState([]);

  const navigate = useNavigate();
  const { currentUser, firstName, lastName } = useAuth();

  useEffect(() => {
    const sortedMessages = [...messagesData].sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );
    setFilteredMessages(sortedMessages);
  }, [messagesData]);

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = messagesData
      .filter(
        (message) =>
          message.status.toLowerCase().includes(term) ||
          message.customerName.toLowerCase().includes(term) ||
          message.postName.toLowerCase().includes(term)
      )
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    setFilteredMessages(filtered);
  };

  const handleCompleteMessage = async (id) => {
    try {
      const messageRef = doc(db, "conversations", id);
      await updateDoc(messageRef, {
        status: "completed",
        completedAt: new Date().toISOString(),
      });

      setFilteredMessages((prevMessages) =>
        prevMessages.map((message) =>
          message.id === id ? { ...message, status: "completed" } : message
        )
      );
    } catch (error) {
      console.error("Error updating message status:", error);
    }
  };
  const handleAssignMeMessage = async (id) => {
    try {
      const employeeName = firstName + " " + lastName;
      const messageRef = doc(db, "conversations", id);
      await updateDoc(messageRef, {
        status: "inProgress",
        employeeName: employeeName,
        employeeId: currentUser.uid,
        employeeAssignedAt: serverTimestamp(),
      });

      setFilteredMessages((prevMessages) =>
        prevMessages.map((message) =>
          message.id === id ? { ...message, status: "inProgress" } : message
        )
      );
    } catch (error) {
      console.error("Error updating message status:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  const handleNavigate = (chatId) => {
    navigate(`/admin/messages/${chatId}`);
  };

  const handlePostNavigate = (postId, e) => {
    e.stopPropagation();
    navigate(`/post/${postId}`);
  };

  return (
    <motion.div
      className="bg-gray-800 bg-opacity-50 backdrop-blur-md shadow-lg rounded-xl p-6 border border-gray-700"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.4 }}
    >
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-gray-100">Messages List</h2>
        <div className="relative">
          <input
            type="text"
            placeholder="Search Messages..."
            className="bg-gray-700 text-white placeholder-gray-400 rounded-lg pl-10 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={handleSearch}
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" size={18} />
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-700">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Customer Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Post
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Actions
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Assigned to
              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-700">
            {filteredMessages.map((message) => (
              <motion.tr
                key={message.id}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                className="border-t border-gray-700 hover:bg-gray-600 cursor-pointer"
                onClick={() => handleNavigate(message.chatId)}
              >
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-100">
                  <div className="flex space-x-2">
                    {message.customerLastMessageStatus === "unread" && (
                      <div className="h-2 w-2 bg-red-500 rounded-full" />
                    )}
                    <span>{message.customerName}</span>
                  </div>
                </td>
                <td
                  onClick={(e) => handlePostNavigate(message.postId, e)}
                  className="flex items-center space-x-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-100 hover:bg-gray-700"
                >
                  <img
                    src={message.postImg}
                    alt={message.postName}
                    className="h-10 object-cover rounded-xl"
                  />
                  <span>{message.postName}</span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  <span
                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      message.status === "completed"
                        ? "bg-green-400 text-green-800"
                        : message.status === "inProgress"
                        ? "bg-yellow-400 text-yellow-800"
                        : "bg-red-400 text-red-800"
                    }`}
                  >
                    {message.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {formatDate(message.updatedAt)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {message.status !== "completed" ? (
                    <>
                      {message.status !== "inProgress" && (
                        <button
                          className="text-yellow-300 hover:text-green-200 mx-5"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleAssignMeMessage(message.id);
                          }}
                          disabled={message.status === "completed"}
                        >
                          Assign me
                        </button>
                      )}
                      <button
                        className="text-green-300 hover:text-green-200 "
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCompleteMessage(message.id);
                        }}
                        disabled={message.status === "completed"}
                      >
                        <Check size={18} />
                      </button>
                    </>
                  ) : (
                    <div className="text-xs text-wrap">No Action available</div>
                  )}
                </td>
                <td>
                  {message.employeeName && (
                    <div className="text-sm">
                      <span className="text-yellow-500">
                        {message.employeeName}
                      </span>
                    </div>
                  )}
                </td>
              </motion.tr>
            ))}
          </tbody>
        </table>
      </div>
    </motion.div>
  );
}

export default MessagesTable;

import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { TailSpin } from "react-loader-spinner";
// import ChatRightSidebar from "./ChatRightSidebar";
import Moment from "react-moment";
import { SendHorizontal } from "lucide-react";
import InputEmoji from "react-input-emoji";
import toast from "react-hot-toast";
import Linkify from "linkify-react";

function ContactsChatSpace({ currentUser, firstName, lastName }) {
  const { uid } = useParams();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const [conversation, setConversation] = useState({});
  const [newMessage, setNewMessage] = useState("");
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const fetchConversationId = async () => {
      try {
        const conversationsRef = collection(db, "contacts");
        const q = query(conversationsRef, where("uid", "==", uid));

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          setConversationId(doc.id);
          setConversation(doc.data());
        } else {
          toast.error("No conversation found for this uid.");
        }
      } catch (error) {
        console.error("Error fetching conversation:", error);
      }
    };

    fetchConversationId();
  }, [uid]);

  const markConversationAsRead = async () => {
    if (conversationId) {
      const conversationRef = doc(db, "contacts", conversationId);
      await updateDoc(conversationRef, {
        customerLastMessageStatus: "read",
      });
    }
  };

  useEffect(() => {
    if (conversationId) {
      const messagesRef = collection(
        db,
        "contacts",
        conversationId,
        "messages"
      );
      const q = query(messagesRef, orderBy("createdAt", "asc"));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const messagesData = snapshot.docs.map((doc) => {
          const data = doc.data();

          const createdAt =
            data.createdAt instanceof Timestamp
              ? data.createdAt.toDate().toISOString()
              : data.createdAt;

          return {
            id: doc.id,
            ...data,
            createdAt,
          };
        });

        setMessages(messagesData);
        setLoading(false);

        if (messagesData.length > 0) {
          markConversationAsRead();
        }
      });
      return () => unsubscribe();
    }
  }, [conversationId]);

  useEffect(() => {
    if (!loading) {
      const timer = setTimeout(scrollToBottom, 100);
      return () => clearTimeout(timer);
    }
  }, [messages, loading]);

  const handleSendMessage = async (e) => {
    let messageText;

    if (typeof e === "string") {
      messageText = e;
    } else {
      e.preventDefault();
      messageText = newMessage;
    }

    if (newMessage.trim() === "") return;
    setSending(true);
    try {
      const messagesRef = collection(
        db,
        "contacts",
        conversationId,
        "messages"
      );
      await addDoc(messagesRef, {
        text: newMessage,
        senderName: firstName + " " + lastName,
        senderId: currentUser.uid,
        createdAt: serverTimestamp(),
      });

      const conversationRef = doc(db, "contacts", conversationId);
      await updateDoc(conversationRef, {
        adminLastMessageStatus: "unread",
        updatedAt: serverTimestamp(),
      });

      setSending(false);
      setNewMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
      setSending(false);
    }
  };

  const handleChangeMessage = (text) => {
    setNewMessage(text);
  };

  return (
    <div className="flex flex-row w-full h-screen z-10">
      <div className="flex flex-col w-full h-screen bg-gray-800">
        <div className="h-12 border-b border-gray-600 flex justify-start text-center items-center p-4 text-gray-300 font-medium">
          <span>Chating with {conversation.name}</span>
        </div>

        <div className="flex-1 max-w-7xl pt-6 px-4 lg:px-8 overflow-y-auto no-scrollbar">
          {loading ? (
            <div className="flex justify-center items-center h-full my-auto space-x-4">
              <TailSpin
                color="#00a3cc"
                strokeWidth={4}
                height={30}
                width={30}
              />
              <span>Loading messages...</span>
            </div>
          ) : (
            <>
              <div className="space-y-4 pb-1">
                {messages.map((msg) => (
                  <div
                    key={msg.id}
                    className={` flex flex-col ${
                      msg.senderId === currentUser.uid ||
                      msg.senderId !== conversation.uid
                        ? "items-end"
                        : "items-start"
                    }`}
                  >
                    <div className="relative flex flex-row">
                      {msg.senderId !== currentUser.uid &&
                      msg.senderId === conversation.uid ? (
                        <>
                          <div className="absolute w-5 h-5 -left-[0.4rem] bottom-0 bg-gray-600 rounded-br-2xl"></div>
                          <div className="absolute w-5 h-5 -left-[1.25rem] bottom-0 bg-gradient-to-br bg-gray-800 rounded-br-xl"></div>
                        </>
                      ) : (
                        <>
                          <div className="absolute w-5 h-5 -right-[0.4rem] bottom-0 bg-mainColor rounded-bl-2xl"></div>
                          <div className="absolute w-5 h-5 -right-[1.25rem] bottom-0 bg-gradient-to-br bg-gray-800 rounded-bl-xl"></div>
                        </>
                      )}

                      <div
                        className={`max-w-md py-2 px-4 ${
                          msg.senderId === currentUser.uid ||
                          msg.senderId !== conversation.uid
                            ? "bg-mainColor text-white rounded-2xl"
                            : "bg-gray-600 text-white rounded-2xl"
                        }`}
                      >
                        <Linkify
                          options={{
                            attributes: {
                              style: {
                                color: "blue",
                                textDecoration: "underline",
                              },
                              target: "_blank",
                            },
                          }}
                        >
                          {msg.text}
                        </Linkify>
                        {msg.senderId !== currentUser.uid &&
                          msg.senderId !== conversation.uid && (
                            <div className="text-xs text-gray-300">
                              sent by:{" "}
                              <span className="text-gray-200">
                                {msg.senderName}
                              </span>
                            </div>
                          )}
                      </div>
                    </div>

                    <div
                      className={`flex text-xs text-gray-400 font-medium mt-1 ${
                        msg.senderId === currentUser.uid ||
                        msg.senderId !== conversation.uid
                          ? "justify-end"
                          : "justify-start"
                      }`}
                    >
                      <Moment fromNow>{msg.createdAt}</Moment>
                    </div>
                  </div>
                ))}
              </div>

              <div ref={messagesEndRef} />
            </>
          )}
        </div>

        <form onSubmit={handleSendMessage} className="px-4 py-4 bg-gray-800">
          <div className="flex items-center relative">
            <div
              className={`transition-all duration-150 ${
                newMessage.trim() || sending ? "mr-10" : "mr-0"
              } w-full`}
            >
              <InputEmoji
                value={newMessage}
                onChange={handleChangeMessage}
                cleanOnEnter
                onEnter={handleSendMessage}
                placeholder="Type your message..."
                background="#1f2937"
                color="#ffffff"
                theme="dark"
                borderColor="#00a3cc"
              />
            </div>
            {sending ? (
              <div className="absolute right-2 py-1 px-3 text-sm rounded-full flex items-center">
                <TailSpin
                  color="#00a3cc"
                  height={20}
                  width={20}
                  strokeWidth={4}
                />
              </div>
            ) : (
              <button
                type="submit"
                disabled={sending || !newMessage.trim()}
                className="absolute right-2 p-1 bg-transparent text-mainColor hover:text-hovermainColor disabled:opacity-0 transition-opacity duration-150 flex items-center"
              >
                <SendHorizontal size={26} />
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactsChatSpace;
